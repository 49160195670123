import axios from "axios";
import { Box } from "@mui/system";
import {
	Button,
	Checkbox,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { DataGridContext } from "../context/DataGridContext";
import React, { useEffect } from "react";
import RouterBreadcrumbs from "../components/Breadcrumbs";
import { API_ENDPOINTS, APP_URL } from "../API/apiConfig";
import SyncIcon from "@mui/icons-material/Sync";
import { motion } from "framer-motion";
import SnackbarContext from "../context/SnackbarContext";
import { useBaking } from "../context/useBaking";
import BakingContext from "../context/BakingContext";
import { toggleSnackbarOpen } from "../redux/actions/snackbarActions";
import { useDispatch } from "react-redux";

export default function Admin() {
	const { getAccessTokenSilently } = useAuth0();
	const {
		isRetail,
		setIsRetail,
		data,
	} = React.useContext(DataGridContext);
	// const { setSnackbar} = React.useContext(SnackbarContext);
	const {baking , isSyncing, setIsSyncing} = React.useContext(BakingContext);
const dispatch = useDispatch()
	const {  refetch,  } = useBaking();
	const variants = {
		rotate: { rotate: 360, transition: { duration: 2, repeat: Infinity } },

	};
	
	const runClearCache = async () => {
		const token = await getAccessTokenSilently();

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		axios
			.get(`${APP_URL}/api/pos/clearCache`, config)
			.then((response) => {
				if (response.status === 200) {
					dispatch(toggleSnackbarOpen({
						message: ["Request was successful"],
						duration: 2000,
						severity: "success",
						open:true

					}));
				} else {
					dispatch(toggleSnackbarOpen({
						message: ["ERROR Request unsuccessful."],
						duration: 4000,
						severity: "error",
						open:true

					}));
				}
	
			})
			.catch((error) => {
				console.error("Error:", error);
				dispatch(toggleSnackbarOpen({
					message: ["ERROR Request unsuccessful.", error.message],
					duration: 8000,
					severity: "error",
					open:true

				}));
			
			});
	};

	const syncMarketMan = async () => {
		setIsSyncing(true);
		let postStatus = 0;

		const token = await getAccessTokenSilently();

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.get(
				`${API_ENDPOINTS["marketManAll"]}`,

				config
			)
			.then(function (response) {
				postStatus = response.status;
				if (postStatus === 200) {
										refetch()
		
				}
			})
			.catch(function (error) {
				setIsSyncing(false);

				postStatus = error.response.status;
				console.log(error, postStatus);
				dispatch(toggleSnackbarOpen({
					message: [`Sync failed - ${error?.response?.data?.msg}`],
					severity: "error",
					open:true

				}));

			});

		
	};

	//post request
	const runOrderbake = async () => {
		const token = await getAccessTokenSilently();
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.post(`${APP_URL}/api/pos/orderBake`, {}, config)
			.then(function (response) {
				if (response.status === 200) {
					dispatch(toggleSnackbarOpen({
						message: ["Request was successful"],
						duration: 4000,
						severity: "success",
						open:true

					}));
				} else {
					dispatch(toggleSnackbarOpen({
						message: ["ERROR Request unsuccessful."],
						duration: 8000,
						severity: "error",
						open:true

					}));
				}
			})
			.catch((error) => {
				console.error("Error:", error);
				dispatch(toggleSnackbarOpen({
					message: ["ERROR Request unsuccessful."],
					duration: 8000,
					severity: "error",
					open:true
				}));
			
			});
	};
	const [buttonCount, setButtonCount] = React.useState(0);
	useEffect(() => {
		let count = 0;
		const screens = data?.screens?.filter((i) => i?.list_isDeleted === 0);
		screens?.forEach(
			(screen) => (count += screen?.list_details?.buttons?.length)
		);
		setButtonCount(count);
	}, [data]);

	return (
		<Box
			sx={{
				maxWidth: "100%",
				maxHeight: "100%",

				display: "flex",
				flexDirection: "column",
				flex: 1,
				ml: 4,
				mr: 4,
			}}
		>
			<Box
				sx={{
					maxWidth: "100%",

					marginTop: 3,
					mb: 2,
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
				}}
			>
				<RouterBreadcrumbs />
			</Box>
			<Box
				sx={{
					width: 400,
					// height: "auto",
					paddingLeft: 10,
					paddingRight: 10,
					paddingTop: 5,
					paddingBottom: 5,

					boxShadow: 3,
					// height: 40,
					display: "flex",
					flexDirection: "column",
					justifyContent: "space-between",
					alignItems: "center",
					gap: 2,
				}}
			>
				<Typography>Settings</Typography>
				<Stack sx={{width:'100%'}}  direction="row"
  justifyContent="space-between"
  alignItems="center"
  spacing={1}>

				<Button size="large" variant="gradient" onClick={runOrderbake}>
					Send to MaxSuite
				</Button>
				<Button size="large" variant="bordered" onClick={runClearCache}>
					Clear Cache
				</Button>
				</Stack>
				
			<Box sx={{ width:'100%',display:'flex', flexDirection:'column', gap:1}}>
	
				  <Button
						variant="gradient"
						size="large"
						sx={{
							width:'100%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', gap:1
						}}
						disabled={isSyncing || baking?.length !== 0}
						onClick={syncMarketMan}
					>MarketMan Sync All
						<motion.div
					
							sx={{
							
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								// height: 30,
								// width: 30,
							}}
							variants={variants}
							animate={isSyncing ? "rotate" : "stop"}
						>
							<Box sx={{ height: 25, width: 25 }}>
								<SyncIcon />
							</Box>
						</motion.div>
					</Button>
				
					{isSyncing && 
					<Typography sx={{fontSize: 12, textAlign:'center' }}>Sync in progress, if you leave this screen you will be notified when complete</Typography>
					 } 
					 	
					</Box>

						<Stack sx={{width:'100%'}}  direction="row"
  justifyContent="space-between"
  alignItems="center"
  spacing={1}>		
  				<Typography >Use Retail Settings</Typography>
							<Checkbox
								checked={isRetail}
								onChange={(event) => {
									setIsRetail(event.target.checked);
								}}
								inputProps={{ "aria-label": "controlled" }}
								sx={{padding: 0}}
							/>
							</Stack>
				
				<Stack sx={{width:'100%'}}  direction="row"
  justifyContent="space-between"
  alignItems="center"
  spacing={1}>		
  				<Typography >Button Count:</Typography>
		
					{data.screens !== null ? (
						<Typography>{buttonCount}</Typography>
					) : (
						<Skeleton>
							<Typography>12345</Typography>
						</Skeleton>
					)}
				</Stack>
			</Box>
		
		</Box>
	);
}
