import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import CustomSwitch from "../../../components/Switch";
import { DataGridContext } from "../../../context/DataGridContext";
import GridContext from "../../../context/GridContext";
import {
	groupBy,
	newPageFill
} from "../../datagrids/utils";
import OptionSelect from "./OptionSelect";

export default function NewPageDialog({
	open,
	setOpen,
	setPage,
}) {
	const { setPageArr, setNewChanges, setVariant } =
		React.useContext(GridContext);

	const { data } = React.useContext(DataGridContext);

	const handleClose = () => {
		setOpen(false);
	};

	let categories = groupBy(data, data.items, function (item) {
		return [item.plu_details.salesGroup];
	});
	const [group, setGroup] = React.useState(categories[0]);
	const [autofillList, setAutofillList] = React.useState(false);

	const handleSubmit = () => {
		setVariant((draft) => {
			const newList = { ...draft };
			newPageFill(
				newList,
				autofillList &&
					group?.items.filter(
						(item) => item.plu_number.charAt(item.plu_number.length - 1) === "0" && item.plu_isDeleted === 0
					),
				data.colours
			);

			setPage(newList.list_details.pages);
			setPageArr(
				Array.from(Array(newList.list_details.pages), (e, i) => i + 1)
			);
		});
		setNewChanges(true);

		setOpen(false);
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "20px",
		width: 300,
		bgcolor: "white",
		boxShadow: 24,
		p: 4,
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		gap: 2,
	};

	return (
		<Modal open={open}>
			<Box sx={style}>
				<Typography variant="h3">New Page</Typography>
<Box sx={{ width: "100%", alignSelf: "flex-start" }}>
					<CustomSwitch
						label="Autofill Page?"
						value={autofillList}
						onChange={() => setAutofillList(!autofillList)}
					/>
				</Box>
				{autofillList && (
					<OptionSelect
						options={categories}
						value={categories.find((i)=> i.group === group.group)}
						label={"Category"}
						onChange={(event) => setGroup(event.target.value)}
						type="group"
						width={"100%"}
						mb={2}
					/>
				)}
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Button onClick={handleClose} variant="outlined">
						Cancel
					</Button>
					<Button variant="contained" onClick={handleSubmit}>
						Add
					</Button>
				</Box>
			</Box>
		</Modal>
	);
}
