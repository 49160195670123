import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { API_ENDPOINTS, APP_URL } from "../../../API/apiConfig";
import { Colours } from "../../../assets/global/Theme-variable";
import { DataGridContext } from "../../../context/DataGridContext";
import GridContext from "../../../context/GridContext";
import { useUserState } from "../../../context/UserContext";
import { contextDeletedFilter, sorter } from "../../datagrids/utils";

export default function ListDeleteDialog({
	deleteErrorMessage,
	onClose,
	setDeleteErrorMessage,
	isSaving,
    setIsSaving,
 variantOptions,
}) {

	const { getAccessTokenSilently } = useAuth0();
const {data, setData} = React.useContext(DataGridContext)
const userPermission = useUserState()

const {
		
    variant,
list, setListOptions,setList,setPage,setVariant, setVariantOptions,
} = React.useContext(GridContext);
const [newMain, setNewMain] = React.useState(null);

const [replaceScreen, toggleReplaceScreen] = React.useState(false);

const handleClose = () => {
    onClose(); // You can call onClose here if needed
    setDeleteErrorMessage((prev) => ({ ...prev, open: false }));
    toggleReplaceScreen(false)
    setNewMain(null)
};


const handleDeleteList = async () => {

  setVariant((draft) => {
    draft.list_isDeleted = 1;

})

let varia = data.screens.find((row) => parseInt(row.uid) === parseInt(variant.uid) || parseInt(row.list_uid) === parseInt(variant.list_uid))
let variaCopy = {...varia}

variaCopy.list_isDeleted = 1;
    const token = await getAccessTokenSilently();

    // const newRows = [...data.screens]
			let newMainCopy = null
			let newMainCopyToDelete = null

        const newRowData = {
            updates: [variaCopy],
        };
        // console.log('varia uid', variaCopy)

// let newMainIndex
        if (newMain !== null) {
        
                            newMainCopy = {...newMain };
                            newMainCopyToDelete = {...newMain };
                            newMainCopyToDelete['list_isDeleted'] = 1
                            newMainCopyToDelete['list_isCurrent'] = 0

                            newMainCopy['list_variant'] = 0
                            newMainCopy['list_isCurrent'] = 1
                           
                    
                            delete newMainCopy.uid;
                            delete newMainCopy.list_uid;

                          
                            newRowData.updates.push(newMainCopy, newMainCopyToDelete);
            
                        } else { 
                            // console.log('list if ne main is null',list)
if(variant.list_variant === 0) {
                            newMainCopy =  
                                
                                    data.screens.findLast((i)=> i.list_number !== variant.list_number && i.list_isDeleted === 0 && i.list_variant === 0)
                                    // console.log('main screen new list',data.screens.findLast((i)=> i.list_number !== variant.list_number && i.list_isDeleted === 0 && i.list_variant === 0))
} else {newMainCopy = list}
                        }
            // console.log('newRow data to post', newRowData)

//     
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
       
        const sitesToUpdate = [];
        const allSites = data?.sites?.map((site) => {
            if (
                site?.site_details?.screen_variants?.some(
                    (obj) =>
                        obj?.hasOwnProperty(JSON.parse(list?.list_number)) &&
                        obj[list?.list_number] === variant?.list_variant
                )
            ) {
                const newVariants = site?.site_details?.screen_variants?.filter(
                    (obj) => {
                        return (
                            !obj?.hasOwnProperty(JSON.parse(variant?.list_number)) &&
                            obj[list?.list_number] !== variant?.list_variant
                        );
                    }
                );
                site.site_details.screen_variants =
                    newVariants !== undefined ? newVariants : [];
                sitesToUpdate.push(site);
                return site;
            }
            return site;
        });
        if (sitesToUpdate.length > 0) {
            const newSiteData = {
                updates: sitesToUpdate,
            };

            axios
                .post(
                    API_ENDPOINTS.site, // Using the endpoint from API_ENDPOINTS
                    newSiteData,
                    config
                )
                .then(function (response) {
                    console.log("res", response);
                })
                .catch(function (error) {
                    console.log("res", error);
                });
        }
        axios
            .post(
                API_ENDPOINTS.list, // Using the endpoint from API_ENDPOINTS
                newRowData,
                config
            )
            .then(function (response) {
                console.log("res", response);

                    axios
                .get(
                    `${APP_URL}/api/pos/getNextSetFromCache/?table=list&uid=0&setSize=9999`, // Using the endpoint from API_ENDPOINTS
                    config
                )
                .then(function (response) {
                    // console.log("res", response);
                const screens = contextDeletedFilter(
                    sorter(response.data.rows, "list_number"),
                    "screens",
                    "list",
                    userPermission
                )
                // console.log('screens', response.data.rows)
                // console.log('newmain', newMainCopy)

                // console.log(screens.find((i)=> i.list_number === newMainCopy.list_number && i.list_variant === 0 && i.list_isDeleted === 0))
                    setData({ ...data, screens: screens, 
                        sites: allSites
                     });
                     setListOptions(
                        screens
                            .filter(
                                (list) =>
                                    // list?.list_details?.location !== "F3" &&
                                    list?.list_details?.ignoreThisList !== true &&
                                    list?.list_variant === 0 &&
                                    list.list_isDeleted === 0
                            )
                            // .sort(sortItems)
                    );
                
                    setList(JSON.parse(JSON.stringify(screens.find((i)=> i.list_number === newMainCopy.list_number && i.list_variant === 0 && i.list_isDeleted === 0))));
                    setVariant(JSON.parse(JSON.stringify(  screens.find((i)=> i.list_number === newMainCopy.list_number && i.list_variant === 0 && i.list_isDeleted === 0))))
                    setVariantOptions(
                        screens.filter(
                            (screen) =>
                                screen.list_number === newMainCopy.list_number &&
                                screen.list_isDeleted === 0
                        )
                    );
                    setPage(1);
                })
               
                })
            .catch(function (error) {
                console.log("res", error);
            });
      

    toggleReplaceScreen(false)
    setNewMain(null)
    setDeleteErrorMessage({
        open: false,
        text: null,
        button1text: "",
        button1func: null,
        button2text: "",
        button2func: null,
    });
    setTimeout(() => {
        setIsSaving(false);

  
    }, 3000);
};

	return (
		<Dialog
			maxWidth={"xs"}
			open={deleteErrorMessage.open || false}
			onClose={handleClose}
			keepMounted
			sx={{ padding: 2 }}
		>
			<DialogTitle
				sx={{
					color: Colours.gsblue,
					fontSize: 18,
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				{/* {deleteErrorMessage.button2text} */}
                Delete '{variant?.list_name}'?
				<IconButton
					aria-label="close"
					sx={{ alignSelf: "flex-end" }}
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
            {(variant?.list_variant !== 0 || (variant?.list_variant === 0 && variantOptions?.filter((v)=> v?.list_variant !== variant?.list_variant).length === 0 )) && <DialogContentText>{deleteErrorMessage.text}</DialogContentText>}
			{variant?.list_variant === 0  &&	
            <Box sx={{width:'100%', display:'flex', flexDirection:'column' }}>             {variantOptions?.filter((v)=> v?.list_variant !== variant?.list_variant).length > 0 &&   <Typography sx={{ fontSize: 14}}>
                Deleting this screen will also delete all associated variants. If you wish to only delete the main screen, please choose a variant to replace it</Typography>}
{variantOptions?.filter((v)=> v?.list_variant !== variant?.list_variant).length > 0 &&	<FormControlLabel sx={{mb: replaceScreen && 2, alignSelf:'flex-end'}}
							control={<Checkbox  checked={replaceScreen} onChange={(e)=> toggleReplaceScreen(e.target.checked)} />}
							label={
								<Typography
									sx={{
										fontSize: 12,
										mb: 0,
										// color: Colours.gsblue,
										justifySelf: "center",
									}}
								>
									Replace main screen
								</Typography>
							}
							labelPlacement="start"
						/>}
                       { replaceScreen  &&	
<FormControl sx={{ width: '100%', }}>
			<InputLabel id="demo-simple-select-label">Variant</InputLabel>
			<Select
				// width={width}
				// sx={{ width: width }}
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				// defaultValue={''}
                value={newMain ?? ''}
				size="small"
				label="Main list"
				onChange={(e, newValue) =>setNewMain(e.target.value)}
				// renderValue={(value) => value.list_name}
			>
				{variantOptions?.filter((v)=> v?.list_variant !== variant?.list_variant).map((option, index) => (
					<MenuItem key={index} value={option}>
						{option.list_name}
					</MenuItem>
				))}
				
			</Select>
		</FormControl>}</Box>}

			</DialogContent>
			<DialogActions
				sx={{ justifyContent: "space-between", pl: 3, pr: 3, pb: 2 }}
			>
				<Button
					// text={errorMessage.button1text}
					variant="outlined"
					onClick={handleDeleteList}
				>
					{deleteErrorMessage.button1text}
				</Button>
				<LoadingButton
					size="small"
					onClick={handleDeleteList}
					loading={isSaving}
					variant={!isSaving ? "gradient" : "outlined"}
					loadingPosition="start"
					startIcon={<SaveIcon />}
					disabled={isSaving}
				>
					<span>{deleteErrorMessage.button2text}</span>
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
}
