import React from "react";
import { Box } from "@mui/material";
import NewGridRefactor from "./NewGridRefactor";

export default function Main({

	colNo,
	rowNo,
	editBar,
	selectedTextColour,
	textAlign,
	selectedColour,
	handleOpen,
	openItem,
	draggingItem,
	activeId,
	setDraggingItem,
	setList,

	setErrorMessage,
}) {

	return (
		<>
			<Box
				display="grid"
				gridTemplateColumns="repeat(10, 1fr)"
				gridTemplateRows="repeat(10, 1fr) "
				sx={{
					flex: 1,
					height: "100%",
					padding: 0,
				}}
			>
				<NewGridRefactor
					handleOpen={handleOpen}
					disableSelection={true}
					setErrorMessage={setErrorMessage}
					colNo={colNo}
					rowNo={rowNo}
					openItem={openItem}
					selectedColour={selectedColour}
					selectedTextColour={selectedTextColour}
					textAlign={textAlign}
					draggingItem={draggingItem}
					setDraggingItem={setDraggingItem}
					editBar={editBar}
					setList={setList}
					activeId={activeId}
				/>
			</Box>
		</>
	);
}
