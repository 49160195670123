import React, { createContext, useContext, useMemo } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Colours } from "../assets/global/Theme-variable";
import { Box } from "@mui/material";

const SortableItemContext = createContext();

export function SortableItem({ children, id }) {
	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
	} = useSortable({ id });
	const context = useMemo(
		() => ({
			attributes,
			listeners,
			ref: setActivatorNodeRef,
		}),
		[attributes, listeners, setActivatorNodeRef]
	);
	const style = {
		opacity: isDragging ? 0.4 : undefined,
		transform: CSS.Translate.toString(transform),
		transition,
		width: "100%",
	};

	return (
		<SortableItemContext.Provider value={context}>
			<Box className="SortableItem" ref={setNodeRef} style={style}>
				{children}
			</Box>
		</SortableItemContext.Provider>
	);
}

export function DragHandle() {
	const { attributes, listeners, ref } = useContext(SortableItemContext);

	return (
		<DragIndicatorIcon
			style={{
				cursor: "move",
				color: Colours.gsblue,
				padding: 0,
				touchAction: "none",
				border: "none",
				outline: 0,
				"&:active": {
					border: "none",
					outline: 0,
				},
				"&:focused": {
					border: "none",
					outline: 0,
				},
			}}
			// onPointerDown={(e) => controls.start(e)}
			{...attributes}
			{...listeners}
			ref={ref}
		/>
	);
}
