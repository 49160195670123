const initialState = {
    open: false,
  };
  
  export default function bulkPricingReducer(state = initialState, action) {
    switch (action.type) {
      case "TOGGLE_BULKPRICING_OPEN": {
        return {
          ...state,
          open: true,
        };
      }
  
      case "TOGGLE_BULKPRICING_CLOSE": {
        return {
          ...state,
          open: false,
        };
      }
  
      default: {
        return state;
      }
    }
  }