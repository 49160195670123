// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  self.addEventListener('message', e => { // eslint-disable-line no-restricted-globals
    console.log('e', e)  
    if (!e) return;
      
      let { users, type } = e.data;

      if(type === "asc") {
        users = users.sort((a, b) => a.commentCount - b.commentCount);
      } else {
        users = users.sort((a, b) => b.commentCount - a.commentCount);
      }

      postMessage(users);
  })
}