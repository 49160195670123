import React, {Suspense} from "react";
// Added this import in to solve the error "ReactDOM.render is no longer supported in React 18. Use createRoot instead. Until you switch to the new API, your app will behave as if it’s running React 17. Learn more:"
import {createRoot} from "react-dom/client";
import App from "./App";
import Spinner from "./components/Spinner/Spinner";
import { Provider } from 'react-redux'
import store from "./redux/store";


// Also refactored here to support React 18s new root api
const root = createRoot(document.getElementById("root"));

root.render(
	<Suspense fallback={<Spinner />}>
							<Provider store={store}> 

		<App />
		</Provider>
	</Suspense>
);
