import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
	Box,
	Button,
	IconButton,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import { API_ENDPOINTS } from "../../../API/apiConfig";
import { Colours } from "../../../assets/global/Theme-variable";
import { DataGridContext } from "../../../context/DataGridContext";
import GridContext from "../../../context/GridContext";

export default function ListVariantForm({
	setModalVisible,
	modalVisible,
	contextName,
	// setOpenDropdown,
}) {
	const { list, setPage, setPageArr, setVariantOptions } =
		React.useContext(GridContext);
	const { data, setData, setVariant } = React.useContext(DataGridContext);
	const { getAccessTokenSilently } = useAuth0();
	const [isSaving, setIsSaving] = React.useState(false);
	const {
		control,
		reset,
		getValues,
		handleSubmit,
		formState,
		formState: { errors },
	} = useForm({
		defaultValues: {},
	});

	React.useEffect(() => {
		if (formState.isSubmitSuccessful) {
			reset();
		}
	}, [formState, reset]);

	const variants = data[contextName]
		.filter(
			(screen) =>
				screen?.list_number === list?.list_number &&
				screen?.list_isDeleted === 0
		)
		.sort((a, b) => a.list_variant - b.list_variant);

	const onSubmit = async () => {
		setIsSaving(true);
		const form = getValues();
		const variant = {
			...list,
		};

		delete variant.uid;
		delete variant.list_ref;
		delete variant.ref;
		delete variant.list_uid;

		variant.list_variant = variants.at(-1).list_variant + 1;
		variant.list_name = form.list_name;
		variant.list_isDeleted = 0;

		const token = await getAccessTokenSilently();
		const newRowData = {
			updates: [variant],
		};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.post(API_ENDPOINTS.list, newRowData, config) // using endpoint from config
			.then(function (response) {
				const newList = response?.data?.list[0];
				newList.uid = response?.data?.list[0].list_uid;

				const newscreens = [...data[contextName], newList];

				setData({
					...data,
					[contextName]: newscreens,
				});

			
				setVariant(newList);

				setVariantOptions(
					newscreens.filter(
						(screen) =>
							parseInt(screen.list_number) === parseInt(list.list_number) &&
							screen.list_isDeleted === 0
					)
				);
				setPage(1);
				setPageArr(
					Array.from(Array(newList.list_details.pages), (e, i) => i + 1)
				);
				setTimeout(() => {
					setIsSaving(false);
				}, 1000);
			})
			.then(() => 	
				setModalVisible(!modalVisible)
			
			)
			.catch(function (error) {
				// console.log("res", error);
			});
	};
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "20px",
		width: 400,
		// minHeight: 400,
		bgcolor: "white",
		boxShadow: 24,
		p: 4,
		display: "flex",
		flexDirection: "column",
		alignItems: "stretch",
		gap: 2,
	};

	return (
		<Modal open={modalVisible}>
			<Box sx={style}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Typography variant="h3">New Variant</Typography>

					<IconButton
						aria-label="close"
						sx={{ alignSelf: "flex-end" }}
						onClick={() => setModalVisible(!modalVisible)}
					>
						<CloseIcon />
					</IconButton>
				</Box>
				<Box sx={{ width: "100%", mt: 2 }}>
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value, ref } }) => (
							<TextField
								label="Variant Name"
								size="small"
								type="variant"
								name="name"
								sx={{ width: "100%" }}
								InputLabelProps={{
									shrink: true,
								}}
								value={value}
								onChange={onChange}
							/>
						)}
						name="list_name"
						rules={{
							required: true,
							pattern: /^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/,
						}}
						defaultValue={""}
					/>

					{errors["list_name"] && errors["list_name"].type === "required" && (
						<Typography sx={{ fontSize: 12, color: Colours.gsblue }}>
							* Name is required.
						</Typography>
					)}
					{errors["list_name"] && errors["list_name"].type === "pattern" && (
						<Typography sx={{ fontSize: 12, color: Colours.gsblue }}>
							Name cannot contain special characters{" "}
						</Typography>
					)}
				</Box>
			
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Button
						variant="outlined"
						size="medium"
						sx={{ alignSelf: "flex-end" }}
						onClick={() => reset()}
					>
						Reset form
					</Button>
					<LoadingButton
						size="small"
						onClick={handleSubmit(onSubmit)}
						loading={isSaving}
						variant={!isSaving ? "contained" : "outlined"}
						loadingPosition="start"
						startIcon={<SaveIcon />}
						disabled={isSaving}
					>
						<span>Save</span>
					</LoadingButton>
				</Box>
			</Box>
		</Modal>
	);
}
