import React, { useContext } from "react";
import { TagConfig } from "../../data grid files/TagConfig";
import { DataGridContext } from "../../../../context/DataGridContext";
import {
	Autocomplete,
	Checkbox,
	FormControlLabel,
	TextField,
} from "@mui/material";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Stack } from "@mui/system";

export default function Module({ item, control, index }) {
	const { data } = useContext(DataGridContext);
	const { resetField, reset, setValue } = useFormContext();

	const output = useWatch({
		name: "tag_details[modules]]",
		control,
		defaultValue: "",
	});

	let tagConfig = TagConfig(item?.behaviour, data);
	let optionConfig = {};
	if (item.hasOwnProperty("behaviour") && item.behaviour !== "") {
		optionConfig = tagConfig;
	} else {
		optionConfig = {};
	}
	const TagConfigData = [
		{
			module_name: "Allergen",
			module_tag: "allergen",
			moduleOptions: [
				{
					option_name: "Severity",
					option_tag: "severity",
					option_type: "list",
					defaultValue: "",

					option_values: [
						{ value: "contains", text: "contains" },
						{ value: "contaminant", text: "contaminant" },
						{ value: "may contain", text: "may contain" },
						{ value: "does not contain", text: "does not contain" },
					],
				},
				{
					option_name: "Allergen",
					option_tag: "allergen",
					option_type: "list",
					defaultValue: "",
					option_values: [
						{ value: "Celery", text: "Celery" },
						{
							value: "Cereals containing gluten",
							text: "Cereals containing gluten",
						},
						{ value: "Crustaceans", text: "Crustaceans" },
						{ value: "Eggs", text: "Eggs" },
						{ value: "Lupin", text: "Lupin" },
						{ value: "Fish", text: "Fish" },
						{ value: "Milk", text: "Milk" },
						{ value: "Molluscs", text: "Molluscs" },
						{ value: "Mustard", text: "Mustard" },
						{ value: "Nuts", text: "Nuts" },
						{ value: "Peanuts", text: "Peanuts" },
						{ value: "Sesame seeds", text: "Sesame seeds" },
						{ value: "Soya", text: "Soya" },
						{ value: "Sulphur Dioxide", text: "Sulphur Dioxide" },
					],
				},
			],
		},
		// {
		// 	module_name: "Dietary",
		// 	module_tag: "dietary",
		// 	moduleOptions: [
		// 		{
		// 			option_name: "Diet",
		// 			option_tag: "diet",
		// 			option_type: "list",
		// 			option_values: [
		// 				{ value: "Vegan", text: "Vegan" },
		// 				{ value: "Vegaterian", text: "Vegaterian" },
		// 				{ value: "Halal", text: "Halal" },
		// 				{ value: "Kosher", text: "Kosher" },
		// 			],
		// 		},
		// 		{
		// 			option_name: "Override",
		// 			option_tag: "override",
		// 			option_type: "True/False",
		// 		},
		// 	],
		// },
		{
			module_name: "Age Check",
			module_tag: "ageCheck",
			moduleOptions: [
				{
					option_name: "Age",
					option_tag: "ageCheck",
					option_type: "list",
					defaultValue: "",

					option_values: [
						{ value: "16", text: "16" },
						{ value: "18", text: "18" },
						{ value: "21", text: "21" },
						{ value: "25", text: "25" },
					],
				},
			],
		},
		{
			module_name: "Discountable",
			module_tag: "discountable",
			moduleOptions: [
				{
					defaultValue: true,
					option_name: "Enable",
					option_tag: "discountable",
					option_type: "True/False",
				},
			],
		},
		{
			module_name: "Printer",
			module_tag: "printer",
			moduleOptions: [
				{
					option_name: "Printer Location",
					option_tag: "printer",
					option_type: "list",
					defaultValue: "",
					option_values: [
						{ value: 0, text: "None" },
						...data?.printers
							?.filter((i) => i?.printer_isDeleted === 0)
							.map((option) => ({
								value: option.printer_ref,
								text: option.printer_name,
							})),
					],
				},
			],
		},

		// Added and ready to use in the second fase, when Tag types is ready
		// {
		// 	module_name: "Location",
		// 	module_tag: "location",
		// 	moduleOptions: [
		// 		{
		// 			option_name: "Concept",
		// 			option_tag: "location",
		// 			option_type: "list",
		// 			option_values: [{ value: "", text: "" }],
		// 		},
		// 		{
		// 			option_name: "Region",
		// 			option_tag: "location",
		// 			option_type: "list",
		// 			option_values: [{ value: "", text: "" }],
		// 		},
		// 	],
		// },

		{
			module_name: "Available Online",
			module_tag: "availableOnline",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "online",
					option_type: "True/False",
					defaultValue: true,
				},
				{
					option_name: "Online Only",
					option_tag: "onlineOnly",
					option_type: "True/False",
					defaultValue: false,
				},
			],
		},
		{
			module_name: "Item Type",
			module_tag: "itemType",
			moduleOptions: [
				{
					option_name: "Type",
					option_tag: "type",
					option_type: "list",
					defaultValue: "",

					option_values: [
						{ value: 0, text: "General" },
						{ value: 1, text: "Extras" },
						{ value: 20, text: "Condiment" },
						{ value: 30, text: "Deposit" },
						{ value: 31, text: "Information" },
						{ value: 40, text: "Dummy" },
						{ value: 41, text: "Qualifier" },
						{ value: 42, text: "Other" },
					],
				},
			],
		},
		{
			module_name: "Price Type",
			module_tag: "priceType",
			moduleOptions: [
				{
					option_name: "Price Options",
					option_tag: "priceType",
					option_type: "list",
					defaultValue: "",
					option_values: [
						{ value: 0, text: "Non-priced" },
						{ value: 2, text: "Preset price" },
						{ value: 1, text: "Price check" },
					],
				},
			],
		},
		{
			module_name: "Analysis Category",
			module_tag: "analysis",
			moduleOptions: [
				{
					option_name: "Analysis Category",
					option_tag: "analysisCategory_number",
					option_type: "list",
					defaultValue: "",
					option_values: data?.analysisCategory
						.filter((i) => i?.analysisCategory_isDeleted === 0)
						.map((option) => ({
							value: option.analysisCategory_number,
							text: option.analysisCategory_name,
						})),
				},
			],
		},
		{
			module_name: "VAT Rating",
			module_tag: "VAT",
			moduleOptions: [
				{
					option_name: "VAT Options",
					option_tag: "VAT",
					option_type: "list",
					defaultValue: "",
					option_values: [
						{
							value: "A",
							text: "A - Standard (20/20)",
							// added in title which is related tio the tool tip
							title: "Standard VAT rate (20% on both goods and services)",
						},
						{
							value: "B",
							text: "B - Zero (0/0)",
							title: "Zero-rated VAT (0% on both goods and services)",
						},
						{
							value: "C",
							text: "C - Reduced (5/5)",
							title: "Reduced VAT rate (5% on both goods and services)",
						},
						{
							value: "D",
							text: "D - Standard (20/0)",
							title: "Standard VAT rate on goods (20%), 0% on services",
						},
						{
							value: "E",
							text: "E - Reduced (5/0)",
							title: "Reduced VAT rate on goods (5%), 0% on services",
						},
						{
							value: "F",
							text: "F - Reduced (5/20)",
							title: "Reduced VAT rate on goods (5%), 20% on services",
						},
					], // has a rate so we show the friendly name of the options
				},
			],
		},
		{
			module_name: "Allow Modifer",
			module_tag: "allowModifer",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "allowModifer",
					option_type: "True/False",
					defaultValue: true,
				},
			],
		},
		{
			module_name: "Not Alone On Kp",
			module_tag: "notAloneOnKp",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "notAloneOnKp",
					option_type: "True/False",
					defaultValue: true,
				},
			],
		},
		{
			module_name: "kp Consolidate Top Item",
			module_tag: "kpConsolidateTopItem",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "kpConsolidateTopItem",
					option_type: "True/False",
					defaultValue: true,
				},
			],
		},
		{
			module_name: "Automatic Quantity (covers)",
			module_tag: "automaticQuantitycovers",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "automaticQuantitycovers",
					option_type: "True/False",
					defaultValue: true,
				},
			],
		},
		{
			module_name: "Free Text",
			module_tag: "freeText",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "freeText",
					option_type: "True/False",
					defaultValue: true,
				},
			],
		},
		{
			module_name: "Free Text and Description",
			module_tag: "freeText_+_Desc",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "freeText_+_Desc",
					option_type: "True/False",
					defaultValue: true,
				},
			],
		},
		{
			module_name: "Sales Tracking",
			module_tag: "salesTracking",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "salesTracking",
					option_type: "True/False",
					defaultValue: true,
				},
			],
		},
		{
			module_name: "EAN On Receipt",
			module_tag: "ean_On_Receipt",
			moduleOptions: [
				{
					option_name: "Enable",
					option_tag: "ean_On_Receipt",
					option_type: "True/False",
					defaultValue: true,
				},
			],
		},
		{
			module_name: "Follow On",
			module_tag: "followOn",
			moduleOptions: [
				{
					option_name: "Screen 1",
					option_tag: "followOn1",
					option_type: "list",
					defaultValue: "",

					option_values: [
						{ value: 0, text: "None" },
						...data.screens
							.filter(
								(i) =>
									i?.list_details?.ignoreThisList !== true &&
									i?.list_variant === 0 &&
									i?.list_isDeleted === 0
							)
							.map((option) => ({
								value: option.list_number,
								text: `${option.list_number} - ${option.list_name}`, // Concatenate list number and name
							})),
					],
				},
				{
					option_name: "Screen 2",
					option_tag: "followOn2",
					option_type: "list",
					defaultValue: "",

					option_values: [
						{ value: 0, text: "None" },
						...data.screens
							.filter(
								(i) =>
									i?.list_details?.ignoreThisList !== true &&
									i?.list_variant === 0 &&
									i?.list_isDeleted === 0
							)
							.map((option) => ({
								value: option.list_number,
								text: `${option.list_number} - ${option.list_name}`, // Concatenate list number and name
							})),
					],
				},
				{
					option_name: "Screen 3",
					option_tag: "followOn3",
					option_type: "list",
					defaultValue: "",

					option_values: [
						{ value: 0, text: "None" },
						...data.screens
							.filter(
								(i) =>
									i?.list_details?.ignoreThisList !== true &&
									i?.list_variant === 0 &&
									i?.list_isDeleted === 0
							)
							.map((option) => ({
								value: option.list_number,
								text: `${option.list_number} - ${option.list_name}`, // Concatenate list number and name
							})),
					],
				},
			],
		},
	];
	const [tagModule, setTagModule] = React.useState(
		TagConfigData.find((i) => i.module_tag === item.behaviour)
	);


	  const behaviourOptions = TagConfigData?.filter(md => 
		output?.every(fd =>{ if (md?.module_tag === 'printer' || md?.module_tag === 'analysis') {return md } else return fd?.behaviour !== md?.module_tag}));
	  
	// const behaviour = watch("behaviour");
	// console.log("tagModule", tagModule);
	// console.log("output", output);
	// React.useEffect(() => {
	// 	// if (!!output[index]) {
	// 	console.log("resetting");
	// 	resetField(`tag_details[modules]][${index}].arguments`, {
	// 		defaultValue: {},
	// 	});
	// }, [output[index]]);
	// React.useEffect(() => {
	// 	reset({ arguments: {} });
	// }, [tagModule]);

	return (
		<>
			<Stack gap={2} sx={{  }}>
				<Controller
					render={({ field: { onChange, value, ref, onBlur }, ...props }) => (
						<Autocomplete
							key={`autocomplete_${item?.field}`}
							options={behaviourOptions}
							value={tagModule  || null}
							sx={{ width: "100%" }}
							freeSolo
							size="small"
							getOptionLabel={(o) => o?.module_name}
							isOptionEqualToValue={(o, v) => o.module_name === v.module_name}
							onChange={(e, newValue) => {
								setTagModule(newValue);
							
								onChange(newValue?.module_tag);
								const defaultVal = {};
								newValue.moduleOptions.forEach((i) => {
									defaultVal[i.option_tag] = i.defaultValue;
								});
								setValue(
									`tag_details[modules]][${index}].arguments`,
									defaultVal
								);
							}}
							// freeSolo
							renderInput={(params) => (
								<TextField
									required
									sx={{ width: "100%", marginTop: 1 }}
									{...params}
									label={"Behaviour"}
									variant={"outlined"}
									InputLabelProps={{
										shrink: true,
									}}
									disabled={item?.editable === false}
								/>
							)}
						/>
					)}
					name={`tag_details[modules]][${index}].behaviour`}
					control={control}
					// defaultValue={item?.behaviour}
				/>
				{tagModule?.moduleOptions?.map((i) =>
					i.option_type === "list" ? (
						<Controller
						key={i.option_name}
							render={({
								field: { onChange, value, ref, onBlur },
								...props
							}) => (
								<Autocomplete
									key={`autocomplete_${item?.field}`}
									options={i?.option_values}
									value={
									
										i?.option_values.find((o) => o?.value === value) || null
										
									}
									isOptionEqualToValue={(o, value) => {
										return o.value === value.value
									}}
									sx={{ width: "100%" }}
									size="small"
									freeSolo
									getOptionLabel={(o) => o?.text ?? ''}
									onChange={(e, newValue) => {
								
										onChange(newValue?.value);
									}}
									// freeSolo
									renderInput={(params) => (
										<TextField
											required
											sx={{ width: "100%", marginTop: 1 }}
											{...params}
											label={i.option_name}
											variant={"outlined"}
											InputLabelProps={{
												shrink: true,
											}}
											disabled={item?.editable === false}
										/>
									)}
								/>
							)}
							name={`tag_details[modules][${index}].arguments[${i.option_tag}]`}
							control={control}
							// tag_details[modules]][${index}].arguments
							defaultValue={`tag_details[modules][${index}].arguments[${i.option_tag}]`}
						/>
					) : i?.option_type === "Integer" ? (
						<Controller
						key={i.option_name}

							render={({
								field: { onChange, value, ref, onBlur },
								...props
							}) => (
								<Autocomplete
									options={TagConfig("list", data)}
									getOptionLabel={(option) => option?.label}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											InputLabelProps={{
												shrink: true,
											}}
											label={i?.option_name}
										/>
									)}
									onChange={onChange}
									{...props}
									limitTags={5}
									size="small"
									// defaultValue={item.Printers.map((printer) => {
									// 	return printerOptions.find(
									// 		(i) => parseInt(i.value) === parseInt(printer)
									// 	);
									// })}
								/>
							)}
							name={`tag_details[modules]][${index}][${i?.option_tag}]`}
							control={control}
							// defaultValue={item?.arguments[i?.option_tag]}
						/>
					) : i?.option_type === "True/False" ? (
						
						<Controller
						key={i.option_name}

							render={({
								field: { onChange, value, ref, onBlur },
								...props
							}) => (
								<FormControlLabel
									control={
										<Checkbox
											inputProps={{ "aria-label": "show text" }}
											disableRipple
											checked={value}
											sx={{ margin: 0 }}
											onChange={onChange}
										/>
									}
									label={i?.option_name}
								/>
							)}
							name={`tag_details[modules]][${index}].arguments[${i?.option_tag}]`}
							control={control}
							// defaultValue={item?.arguments[i?.option_tag]}
						/>
					) : (
						<></>
					)
				)}
			</Stack>
		</>
	);
}
