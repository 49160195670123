import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Button, Menu } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import * as React from "react";
import { DataGridContext } from "../../../context/DataGridContext";


const ITEM_HEIGHT = 40

export default function PricingFilterSites() {
	const { data, siteFilterValue, setSiteFilterValue, setPriceBandFilterValue } =
		React.useContext(DataGridContext);

	const siteFilterData = data.sites;

	const [isUpdating, setIsUpdating] = React.useState(false);
	const [checked, setChecked] = React.useState(true);

// console.log('here', siteFilterValue)

	React.useEffect(() => {
		if(checked) {
		setSiteFilterValue(siteFilterData.map((site) => site.site_ref));
		// setPriceBandFilterValue(["1"]);
		} else {
			setSiteFilterValue([]);
		// setPriceBandFilterValue(["1"]);	
		}
	}, [checked,siteFilterData, setSiteFilterValue]);


	const handleSiteChange = (number) => {
		
		let val = [...siteFilterValue];

		if (val.includes(number)) {
			val = siteFilterValue.filter((i) => i !== number);
		} else {
			val.push(number);
		}
		setIsUpdating(true);
		setSiteFilterValue(val);
	};
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	React.useEffect(() => {
		if (isUpdating) {
			setIsUpdating(false);
		}
	}, [isUpdating]);

	// React.useEffect(() => {
	// 	if (checked) {
	// 		setIsUpdating(false);
	// 	}
	// }, [checked]);

	if (data.sites.length > 1) {
		return (
			<div>
				<Button
					id="basic-button"
					aria-controls={open ? "basic-menu" : undefined}
					aria-haspopup="true"
					aria-expanded={open ? "true" : undefined}
					onClick={handleClick}
				>
					Sites
					<FilterAltIcon />
				</Button>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
					slotProps={{
						paper: {
						  style: {
							maxHeight: ITEM_HEIGHT * 4.5,
							width: '20ch',
						  },
						},
					  }}
				>
						<MenuItem
							key={'check'}
							value={checked}
							onClick={() => setChecked(!checked)}
						>
							<Checkbox checked={checked} />
							<ListItemText primary={checked ? 'Unselect all' : 'Select All'} />
						</MenuItem>
					{siteFilterData.map((site) => (
						<MenuItem
							key={site.site_name}
							value={site.site_ref}
							onClick={() => handleSiteChange(site.site_ref)}
						>
							<Checkbox checked={siteFilterValue.indexOf(site.site_ref) > -1} />
							<ListItemText primary={site.site_name} />
						</MenuItem>
					))}
				</Menu>
			</div>
		);
	}
}
