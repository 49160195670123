import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Typography } from "@mui/material";
import React, { useContext } from "react";
import Spinner from "../components/Spinner/Spinner";
import { MyFallbackComponent } from "../views/FallBackComponent";
import AccessControl from "../views/AccessControl";
import NoPermission from "../views/NoPermission";
import { DataGridContext } from "../context/DataGridContext";
import { useFetch } from "../views/profile/useFetch";
import { useLoggedInUserState, UserContext, useUserState, useUserUpdater } from "../context/UserContext";

export const AuthenticationGuard = ({ component, page, friendlyName, strict }) => {
	const { error } = useAuth0();

	// const { error, loggedInUser, isAuthenticated } = useFetch();
const [loading, setLoading] = React.useState(false)
	// const {  error } = useLoggedInUserState();
	const userPermission = useUserState();

	// React.useEffect(() => {
	// 	console.log('getting user auth guard')
	// 	getUser().then(()=> setLoading(false))

	// },[])
	
	const Component = withAuthenticationRequired(component, {
		onRedirecting: () => <Spinner />,
	});
	if (error) {
		console.log('error', error)
		return <MyFallbackComponent error={error?.message} />;
	}
	if ( loading || !userPermission) {
		return <Spinner />;
	}

	return (
		<AccessControl
			userPermissions={userPermission}
			unallowedPermissions={{
				page:page,
				// allow: false,
				action: "view",
				strict: strict ? strict : false,
			}}
			renderNoAccess={() => (
				<NoPermission
					dbName={friendlyName ? friendlyName : page}
				/>
			)}
		>
			<Component  />
		</AccessControl>
	);
};
