import { useAuth0 } from "@auth0/auth0-react";
import CloseIcon from "@mui/icons-material/Close";
import {
	Box,
	Button,
	IconButton,
	Modal,
	Stack,
	Typography
} from "@mui/material";
import axios from "axios";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { API_ENDPOINTS } from "../../../API/apiConfig";
import { DataGridContext } from "../../../context/DataGridContext";
import GridContext from "../../../context/GridContext";
import NewItemForm from "../../datagrids/DataGridRefactor/components/NewItemForm";
import { Config } from "../../datagrids/DataGridRefactor/Config";

export default function NewItemModal({
	setNewItemModalOpen,
	newItemModalOpen,
}) {
	const { data, setData } = React.useContext(DataGridContext);

	const { getAccessTokenSilently } = useAuth0();
	const { setVariant, variant, openItem } = React.useContext(GridContext);

	const [isSaving, setIsSaving] = React.useState(false);

	const onSubmit = async () => {
		setIsSaving(true);

			let form = methods.getValues();

		form = form.plus;
		
		const token = await getAccessTokenSilently();

	let buttonIndex = variant?.list_details?.buttons?.findIndex(function (
		object
	) {
		return object?.id === openItem?.id;
	});
	const newRowData = {
		updates: Array.isArray(form) ? form : [form],
	};
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};
		axios
			.post(API_ENDPOINTS.plu, newRowData, config) // USING ENDPOINT FROM CONFIG
			.then(function (response) {
				let newItem = response.data.plu[0];
				response.data.plu.forEach(
					(plu) => (
						(plu.uid = plu.plu_uid),
						(plu.plu_number = plu.plu_number.toString())
					)
				);

				setData({
					...data,
					items: [...data.items, ...response?.data?.plu],
				});
				setVariant((draft) => {
					draft.list_details.buttons[buttonIndex].functionValue = parseInt(
						newItem.plu_number
					);

					draft.list_details.buttons[buttonIndex].functionType = 21;
					draft.list_details.buttons[buttonIndex].text = newItem.plu_name;
				});
				setTimeout(() => {
					setIsSaving(false);
					setNewItemModalOpen(false);

				}, 2000);
			})
			.catch(function (error) {
				console.log(error);
				setIsSaving(false);
			});
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		borderRadius: "10px",
		width: 450,
		maxHeight: "90vh",
		bgcolor: "white",
		boxShadow: 24,
		overflow: "hidden",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		gap: 2,
		flexDirection: "column",
	};

	const style2 = {

		height: "100%",
		width: "100%",

		p: 4,
		overflow: "hidden",
		display: "flex",
		justifyContent: "flex-start",
		gap: 2,
		flexDirection: "column",
		
		overflowY: "scroll",

		"&::-webkit-scrollbar": {
			width: "6px",
		},
		"&::-webkit-scrollbar-track": {
			backgroundColor: "#f5f5f5",
		},
		"&::-webkit-scrollbar-thumb": {
			borderRadius: "10px",
			boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
			// backgroundColor: "#f5f5f5",
		},
	};
	let gridList = Config('items');

	const methods = useForm({
		defaultValues: gridList.defaultValues,
	});

	return (
		<FormProvider {...methods}>
		<Modal open={newItemModalOpen}>
		<form onSubmit={methods.handleSubmit(onSubmit)}>
		<Box sx={style}>
							<Box sx={style2}>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<Typography variant="h3">
										New Item
									</Typography>

									<IconButton
										aria-label="close"
										sx={{ alignSelf: "flex-end" }}
										onClick={() => {

											setNewItemModalOpen(false);
											methods.reset();
										}}
									>
										<CloseIcon />
									</IconButton>
								</Box>

								<Stack
									direction="column"
									justifyContent="flex-start"
									alignItems="flex-start"
									spacing={2}
									sx={{ mt: 2, mb: 2 }}
								>
		 <NewItemForm methods={methods}/>
		 </Stack>

<Box
	sx={{
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
	}}
>
	<Button
		variant="outlined"
		size="medium"
		sx={{ alignSelf: "flex-end" }}
		onClick={() => methods.reset()}
	>
		Reset form
	</Button>
	<Button
		variant="gradient"
		size="medium"
		sx={{ alignSelf: "flex-end" }}
		type="submit"
	>
		Submit
	</Button>
</Box>
</Box>
</Box>
		 </form>
		</Modal>
		</FormProvider>
	);
}


