import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';

export default function ExamplePricingTable({row}) {
  return (
    <TableContainer >
      <Table sx={{ }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell><Typography>Item</Typography></TableCell>
            <TableCell align="right"><Typography>Current</Typography></TableCell>
            <TableCell align="right"><Typography>New</Typography></TableCell>
            <TableCell align="right"><Typography>Difference</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow
              key={row?.plu_name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row?.plu_name}
              </TableCell>
              <TableCell align="right"><Typography>£{parseFloat(row?.old).toFixed(2)}</Typography></TableCell>
              <TableCell align="right"><Typography>{!isNaN(row?.difference) ? `£${parseFloat(row?.new).toFixed(2)}` : `£${parseFloat(row?.old).toFixed(2)}` }</Typography></TableCell>
              <TableCell align="right"><Typography>{!isNaN(row?.difference) ? `£${parseFloat(row?.difference).toFixed(2)}` : `£0` }</Typography></TableCell>
            </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}