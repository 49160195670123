import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { Chip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useGridApiContext } from "@mui/x-data-grid";
import { Colours } from "../../../../assets/global/Theme-variable";

export const AutocompleteChipCell = (props) => {
	const [selectedOptions, setSelectedOptions] = React.useState(
		props.value || []
	);
	const { id, field, value, options, type, fieldToSave, fieldToShow } = props;
	const ref = useGridApiContext();
// console.log(value)
	React.useEffect(() => {
		if (value) {
			ref.current.setEditCellValue({
				id,
				field,
				value: selectedOptions.map((val) => val && val[`${type}_ref`]),
			});
			ref.current.stopCellEditMode({ id, field });
		}
	}, [selectedOptions]);

	return (
		<Stack spacing={3} sx={{ width: "100%" }}>
			<Autocomplete
				sx={{
					width: "100%",
					"& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
						border: "none",
						// borderColor: "black",
					},
					"& .MuiInput-root::before .MuiInput-root::hover": {
						border: "none",
					},
				}}
				multiple
				variant="outlined"
				id="tags-standard"
				options={options}
				getOptionKey={(option) => option[`${type}_uid`]}
				getOptionLabel={(option) => option[`${type}_name`]}
				isOptionEqualToValue={(option, value) => {
					if (
						option &&
						option[`${type}_ref`] &&
						value &&
						value[`${type}_ref`]
					) {
						return (
							parseInt(option[`${type}_ref`]) === parseInt(value[`${type}_ref`])
						);
					}
					return false;
				}}
				value={selectedOptions}
				renderTags={(value, getTagProps) =>
					value?.map((option, index) =>{
						const { key, ...rest } = getTagProps({ index });
						return (
						<Chip
							variant="filled"
							label={option && option[`${type}_name`]}
							size="medium"
							// {...getTagProps({ index })}
							key={key}
							{...rest}
							deleteIcon={<CloseIcon size={10} />}
							sx={{
								borderRadius: "6px",
								backgroundColor: option?.tag_details?.styling
									? option?.tag_details?.styling?.colour
									: Colours.gsblue,
								padding: "2px 4px 2px 8px",
								color: "white",
								borderColor: option?.tag_details?.styling
									? option?.tag_details?.styling?.colour
									: Colours.gsblue,
								// height: "24px",
								// margin: "2px",
								// lineHeight: "22px",
								fontSize: 10,

								"& svg": {
									fontSize: "14px",
									cursor: "pointer",
									// padding: "4px",
									color: "white !important",
								},
								"& .MuiChip-deleteIcon": {
									fontSize: "12px",
								},
							}}
						/>
					)})
				}
				onChange={(e, newValue) => setSelectedOptions(newValue)}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="standard"
						InputProps={{ ...params.InputProps, disableUnderline: true }}

						// label="Multiple values"
						// placeholder="Favorites"
					/>
				)}
			/>
		</Stack>
	);
};
