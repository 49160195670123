import { useLoggedInUserState, useLoggedInUserUpdater, useUserState, useUserUpdater } from "./context/UserContext"
import FullLayout from "./layouts/FullLayout/FullLayout"
import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { MyFallbackComponent } from "./views/FallBackComponent";
import worker from './worker/app.worker.js';
import WebWorker from "./worker/webWorker.js";
import LandingPage from "./views/Landing.js";

const Main = () => {
    const {  user, isAuthenticated,error } = useAuth0();
    const getUser = useUserUpdater()
    const webWorker = new WebWorker(worker);
    const updateLoggedInUser = useLoggedInUserUpdater()
const loggedInUser = useLoggedInUserState()
const userPermission = useUserState()
// const hasLoaded = true
// if (typeof window !== 'undefined' && !loggedInUser && user) { // Check if we're running in the browser.
//     // ✅ Only runs once per app load
//     // if(!loggedInUser && user) {
//         console.log('here uliu')
//     updateLoggedInUser(user)
//     getUser()

//     // } 
//  }
    React.useEffect(()=> {
        if(!loggedInUser && user) {
        updateLoggedInUser(user)
        getUser()
        } 
    },[user, loggedInUser, updateLoggedInUser, getUser])

  
   React.useEffect(() => {
    if (userPermission) {
if(user){

    const interval = setInterval(() => {
        getUser()
      
        return () => {
            webWorker.terminate()
        }      }, 10000);
  
      return () => clearInterval(interval);
}  else console.log('cant as no:', user)
    }
    }, [user, getUser]);

	if (error) {
		return <MyFallbackComponent error={error.message} />;
	} else
    return (
        <>
        {isAuthenticated && user ? (
   <FullLayout />
        ): <LandingPage/>}
    </>)
}

export default Main