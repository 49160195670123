import React, { useContext, useEffect, useState } from "react";

import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { Colours } from "../assets/global/Theme-variable";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import NewModifierModal from "./NewModifierModal";
import { DataGridContext } from "../context/DataGridContext";
import { ModifierList } from "./ModifierList";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { arrayMoveImmutable } from "array-move";
import ErrorDialog from "../views/grid/components/ErrorDialog";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AccessControl from "../views/AccessControl";
import { SortableList } from "./SortableList";
import { Modifier } from "./Modifier";
import { useUserState } from "../context/UserContext";

export default function ItemModifiers({
	item,
	setItem,
	modifiers,
	setModifiers,
}) {
	const [modalVisible, setModalVisible] = React.useState(false);
	const { data, setData } = useContext(DataGridContext);
	const userPermission = useUserState()

	const [error, setError] = React.useState({
		open: false,
		text: null,
	});
	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const handleRemove = (id) => {
		const newModifiers = modifiers.filter((modifier) => modifier.id !== id);

		setModifiers(newModifiers);

		const mutatedMods = newModifiers.map((mod) => {
			return { modifier: mod.modifier, type: mod.type, ref: mod.ref };
		});

		item.plu_details.priceModifiers = mutatedMods;

		setItem({
			...item,
			plu_details: { ...item.plu_details, priceModifiers: mutatedMods },
		});
		console.log(item);
	};

	return (
		<>
			<NewModifierModal
				modalVisible={modalVisible}
				setModalVisible={setModalVisible}
				modifiers={modifiers}
				setModifiers={setModifiers}
				item={item}
				setItem={setItem}
				error={error}
				setError={setError}
			/>
			<ErrorDialog errorMessage={error} setErrorMessage={setError} />
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "flex-start",
					alignItems: "flex-start",
					width: "100%",
					height: "100%",
					overflow: "auto",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						width: "100%",
						height: "10%",
						mb: 2,
					}}
				>
					<Box
						sx={{
							width: 70,
							// height: 30,
							display: "flex",
							justifyContent: "center",
						}}
					>
						<Typography sx={{ fontSize: 12 }}>Priority</Typography>
						<Tooltip
							open={open}
							// placement="bottom-end"
							onClose={handleClose}
							onOpen={handleOpen}
							title="Variants are applied to prices in priority order and the variant with the highest priority will override those below it. For example, if a PLU has a variant for 'Site 1' and a tag modifier for 'Manchester', if the 'Site 1' variant has a higher priority, it will override the 'Manchester' variant, and the price for 'Site 1' will be shown."
							disableFocusListener
							disableTouchListener
							placement="right-start"
						>
							<HelpOutlineIcon
								style={{
									cursor: "help",
									color: Colours.gsblue,
									padding: 0,
									fontSize: "medium",
								}}
							/>
						</Tooltip>
					</Box>
					<Box
						sx={{
							// width: 50,
							// height: 30,
							display: "flex",
							justifyContent: "center",
						}}
					>
						<AccessControl
							userPermissions={userPermission}
							unallowedPermissions={{
								page: "items",
								// allow: false,
								action: "edit",
								strict: false,
							}}
							// renderNoAccess={() => <NoAccess permissionsNeeded="read:stats" />}
							renderNoAccess={() => <></>}
						>
							<IconButton
								aria-label="add modifier"
								onClick={() => setModalVisible(!modalVisible)}
								sx={{ borderRadius: 1 }}
							>
								{/* <AddIcon color={Colours.gsblue} /> */}
								<Typography sx={{ fontSize: 14 }} color={Colours.gsblue}>
									+ ADD NEW
								</Typography>
							</IconButton>
						</AccessControl>
					</Box>
				</Box>
				{/* <Box
					sx={{
						width: "100%",
						height: "100%",
						display: "flex",
						flexDirection: "row",
					}}
				> */}
				{/* <Box
						sx={{
							width: "5%",
							display: "flex",
							flexDirection: "column",
							gap: "10px",
							mr: 2,
						}}
					> */}
				{/* {modifiers.map((modifier, index) => (
							<Box
								sx={{
									height: 40,
									display: "flex",
									justifyContent: "flex-end",
									alignItems: "center",
								}}
							>
								{index === 0 ? (
									<KeyboardArrowDown
										onClick={() =>
											setModifiers(
												arrayMoveImmutable(modifiers, index, index + 1)
											)
										}
										style={{
											color: Colours.gsblue,
											padding: 0,
										}}
									/>
								) : index === modifiers.length - 1 ? (
									<KeyboardArrowUp
										onClick={() =>
											setModifiers(
												arrayMoveImmutable(modifiers, index, index - 1)
											)
										}
										style={{
											color: Colours.gsblue,
											padding: 0,
										}}
									/>
								) : (
									<>
										<KeyboardArrowDown
											onClick={() =>
												setModifiers(
													arrayMoveImmutable(modifiers, index, index + 1)
												)
											}
											style={{
												color: Colours.gsblue,
												padding: 0,
											}}
										/>
										<KeyboardArrowUp
											onClick={() =>
												setModifiers(
													arrayMoveImmutable(modifiers, index, index - 1)
												)
											}
											style={{
												color: Colours.gsblue,
												padding: 0,
											}}
										/>
									</>
								)}
							</Box>
						))}
					</Box> */}
				{modifiers?.length > 0 ? (
					<SortableList
						items={modifiers}
						onChange={setModifiers}
						renderItem={(mod) => (
							<SortableList.Item id={mod?.id}>
								<Modifier
									item={item}
									removeItem={handleRemove}
									modifiers={modifiers}
									setItem={setItem}
									setModifiers={setModifiers}
									mod={mod}
								/>
							</SortableList.Item>
						)}
					/>
				) : (
					<Typography>No Price Modifiers</Typography>
				)}
			</Box>
		</>
	);
}
