import SaveIcon from "@mui/icons-material/Save";
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	TextField,
	Typography
} from "@mui/material";
import React, { useEffect } from "react";

import { LoadingButton } from "@mui/lab";
import { Slider, Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { Colours } from "../../../assets/global/Theme-variable";
import { DataGridContext } from "../../../context/DataGridContext";
import { groupBy } from "../../datagrids/utils";
import layouts from "../data/mainlayouts";
import OptionSelect from "./OptionSelect";

export default function NewListForm({

	onSubmit,
	isSaving,
}) {
	const { data } = React.useContext(DataGridContext);

	const categories = groupBy(data, data?.items, function (item) {
		return [item?.plu_details?.salesGroup];
	});

	const {
		register,
		control,
		watch,
		reset,
		unregister,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm({
		defaultValues: {
			list_name: "",
			layout: layouts[0],
			group: categories[0],
			autofilllist: false,
		},
		shouldUnregister: true,
	});

	// const layoutOpt = watch("area");

	const [minMax, setMinMax] = React.useState([0, 1]);

	const handleRangeChange = (event, newValue) => {
		setMinMax(newValue);
		setValue("selMin", newValue);
	};

	const watchAutofill = watch("autofilllist");

	useEffect(() => {
		if (watchAutofill) {
			register("group");
		} else {
			unregister("group");
		}
	}, [register, unregister, watchAutofill]);

	const listNames = data?.screens
		?.filter((i) => i.list_isDeleted === 0)
		.map((list) => list?.list_name);
	return (
		<Box
			component="form"
			sx={{
				display: "flex",
				flexDirection: "column",
				// justifyContent: "flex-start",
				justifyContent: "space-between",
				// gap: 2,
				// minHeight: "100%",
				flex: 1,
			}}
		>
			<Box
				sx={{
					mt: 2,
					mb: 2,
					display: "flex",
					gap: 2,
					flexDirection: "column",
					height: "100%",
				}}
			>
				<Box>
					<TextField
						id="outlined-controlled"
						label="List Name"
						size={"small"}
						type="align"
						name="name"
						InputLabelProps={{
							shrink: true,
						}}
						sx={{ width: "100%" }}
						{...register("name", {
							validate: {
								required: (value) => {
									if (listNames.includes(value))
										return "A list with this name already exists";
									if (!value) return "*Required";
								},
								inputText: (input) => {
									if (
										!input.match(
											/^[-a-zA-Z0-9@\s\*&+%£=!:;><)?(^\[\]{\}\\\/\.\_]+$/
										)
									)
										return "Name cannot contain special characters";
								},
							},
							shouldUnregister: true,
						})}
					/>
					{errors.name && (
						<Typography sx={{ fontSize: 12, ml: 1, color: Colours.gsblue }}>
							{errors.name.message}
						</Typography>
					)}
				</Box>
			
				<Controller
					control={control}
					render={({ field: { onChange, onBlur, value, ref } }) => (
						<OptionSelect
							options={layouts}
							value={value}
							type="layout"
							label={"Layout"}
							width="100%"
							onChange={onChange}
						/>
					)}
					name="layout"
				/>
				
				<Controller
					name="staydown"
					control={control}
					render={
						({ field }) => (
							<FormControlLabel
								control={<Checkbox />}
								label={<Typography sx={{ fontSize: 14 }}>Staydown</Typography>}
								{...field}
							/>
						)
					}
				/>
				<Controller
					name="selMin"
					control={control}
					defaultValue={[0, 1]}
					render={(field) => (
					
						<Box
							sx={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								justifyContent: "center",
							}}
						>
							<Typography
								sx={{ fontSize: 14, textAlign: "center", mb: 2 }}
								id="input-slider"
								gutterBottom
							>
								Number of Selections
							</Typography>
							<Stack
								spacing={2}
								direction="row"
								sx={{ mb: 1, width: "100%" }}
								alignItems="center"
							>
								<Typography
									sx={{ fontSize: 12, textAlign: "center", lineHeight: 1 }}
								>
									{minMax[0]} min
								</Typography>
								<Slider
									// {...field}
									getAriaLabel={() => "Item selection range"}
									value={minMax}
									onChange={(event, newvalue) =>
										handleRangeChange(event, newvalue)
									}
									// valueLabelDisplay="auto"
									min={0}
									max={10}
								/>
								<Typography
									sx={{ fontSize: 12, textAlign: "center", lineHeight: 1 }}
								>
									{minMax[1]} max
								</Typography>
							</Stack>
						</Box>
					)}
				/>
				<Controller
					name="autofilllist"
					control={control}
					render={
						({ field }) => (
							<FormControlLabel
								control={<Checkbox />}
								label={
									<Typography sx={{ fontSize: 14 }}>
										Autofill List with Category?
									</Typography>
								}
								{...field}
							/>
						)
					}
				/>
				{watchAutofill && categories?.length > 0 && (
					<Controller
						control={control}
						render={({ field: { onChange, onBlur, value, ref } }) => (
							<OptionSelect
								options={categories}
								value={value}
								defaultoption={categories[0]}
								type="group"
								label={"Category"}
								width="100%"
								onChange={onChange}
							/>
						)}
						name="group"
					/>
				)}
			</Box>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					justifySelf: "flex-end",
				}}
			>
				<Button
					variant="outlined"
					size="medium"
					sx={{ alignSelf: "flex-end" }}
					onClick={() => reset()}
				>
					Reset form
				</Button>
				<LoadingButton
					size="small"
					onClick={handleSubmit(onSubmit)}
					loading={isSaving}
					variant={!isSaving ? "contained" : "outlined"}
					loadingPosition="start"
					startIcon={<SaveIcon />}
					disabled={isSaving}
				>
					<span>Save</span>
				</LoadingButton>
			</Box>
		</Box>
	);
}
