import { useAuth0 } from "@auth0/auth0-react";
import React, { useContext, useEffect, useState } from "react";

import { Box, Button, Typography, alpha } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";
import LogoIcon from "../components/LogoIcon";
import { Colours } from "../assets/global/Theme-variable";
import {
	AnimatePresence,
	useAnimation,
	motion,
	MotionConfig,
} from "framer-motion";
import useMediaQuery from "@mui/material/useMediaQuery";

import mockup2 from "../assets/images/landingpage2.png";
import landing3 from "../assets/images/landing3.png";

import GradientText from "../components/GradientText";
import { useUserUpdater } from "../context/UserContext";

export default function LandingPage() {
	const { error, loginWithRedirect, loginWithPopup, user, isAuthenticated } =
		useAuth0();
	const navigate = useNavigate();
	const matches = useMediaQuery("(min-width:600px)");
	const [Loading, setIsLoading] = useState(false);
const getUser = useUserUpdater()
	// const itemRight = {
	// 	hidden: { opacity: 0, x: "100%" },
	// 	enter: {
	// 		opacity: 1,
	// 		x: 0,
	// 		y: 0,
	// 		transition: {
	// 			staggerChildren: 0.5,
	// 			ease: "easeInOut",
	// 			duration: 2,
	// 		},
	// 	},
	// 	exit: { opacity: 0, x: "100%" },
	// };

	const handleLogin = async () => {
		try {
			await loginWithPopup({
				appState: {
					returnTo: `${window.location.origin}`,
				},
			});
			getUser()
		} catch (error) {
			console.log("errrrr", error);
			// showBoundary(error);
		} 
	};

	const navigateToHome = () => {
		navigate("/");
	};

	const variants = {
		hidden: { opacity: 1 },
		enter: {
			opacity: 1,
			x: 0,
			y: 0,
			transition: {
				staggerChildren: 0.5,
				ease: "easeInOut",
				duration: 2,
			},
		},
		exit: {
			opacity: 0,
			x: 0,
			y: -100,
			transition: {
				staggerChildren: 0.5,
			},
		},
	};
	const controls = useAnimation();
	useEffect(() => {
		if (!Loading) {
			controls.start("enter");
		} else {
			controls.start("hidden");
		}
	}, [Loading, controls]);

	// if (error) {
	// 	return <MyFallbackComponent error={error.message} />;
	// } else
	return (
		<motion.div
			variants={variants}
			initial="hidden"
			animate={controls}
			exit="exit"
			style={{
				display: "grid",
				gridTemplateColumns: `repeat(16, 1fr) `,
				gridTemplateRows: `0.5fr repeat(10, 1fr) 0.5fr`,
				width: "100vw",
				height: "100vh",
				// background: Colours.gsblue,
				overflow: "hidden",
			}}
		>
			{/* <Box
				sx={{
					// display: "flex",
					// flexDirection: "column",
					// alignItems: "center",
					// justifyContent: "center",
					// display: "grid",
					// gridTemplateColumns: `repeat(16, 1fr)`,
					// gridTemplateRows: `repeat(12, 1fr)`,
					// width: "100vw",
					// height: "100vh",
					// background: Colours.gsblue,
					// overflow: "hidden",
					// backgroundImage: backgroundImg,
					// backgroundImage: `url(${backgroundImg})`,
					// backgroundPosition: `right bottom`,

					// backgroundSize: "cover",
					// backgroundRepeat: "no-repeat",
				}}
			> */}
			{/* <Box sx={{display: "grid", gridTemplateColumns: 16, gridTemplateRows: 8}}> */}
			<Box
				sx={{
					// background: "pink",
					gridColumn: ` 2 / span 3`,
					gridRow: `2 / span 1`,
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-start",
				}}
			>
				<LogoIcon height={80} />
			</Box>
			<Box
				sx={{
					// background: "pink",
					gridColumn: matches ? ` 10 / span 6` : ` 1 / span 15`,
					gridRow: matches ? `4 / span 8` : `7 / span 8`,
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
				}}
			>
				{/* <motion.div
					whileHover={{
						scale: 1.05,
						transition: {duration: 0.5},
					}}
					onHoverEnd={(e) => {}}
					// style={{position: "absolute"}}
					variants={itemRight}
					// whileTap={{scale: 0.9}}
				> */}
				<img
					alt="Mockup"
					src={landing3}
					style={{ width: matches ? 650 : 500, height: "auto" }}
				/>
			</Box>

			<Box
				sx={{
					// background: "pink",
					gridColumn: matches ? ` 2 / span 6` : "2 / span 3",
					gridRow: matches ? `5 / span 5` : "3 / span 5",
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-start",
				}}
			>
				<GradientText />
			</Box>
			{/* <Box
				sx={{
					// background: "pink",
					gridColumn: ` 12 / span 2`,
					gridRow: `2`,
					display: "flex",
					alignItems: "center",
					justifyContent: "space-around",
				}}
			>
				<Typography>ABOUT</Typography>
				<Typography>CONTACT</Typography>
			</Box> */}
			<Box
				sx={{
					// background: "pink",
					gridColumn: matches ? ` 14 / span 2` : `12 / span 4`,
					gridRow: `2`,
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					gap: 4,
				}}
			>
				{/* <Typography>ABOUT</Typography>
				<Typography>CONTACT</Typography> */}
				{isAuthenticated && user ? (
					<Button
						sx={{ width: "100%", height: "100%" }}
						onClick={navigateToHome}
					>
						Enter
					</Button>
				) : (
					// <Button onClick={handleLogin}>Log In</Button>
					<Button
						sx={{
							width: "50%",
							height: "50%",
							color: "white",
							backgroundColor: Colours.gsblue,
							borderRadius: 2,
							boxShadow: 10,
						}}
						variant="contained"
						onClick={handleLogin}
					>
						Log In
					</Button>
				)}
			</Box>
			{/* <Box
				sx={{
					// background: "pink",
					gridColumn: ` 6/ span 8`,
					gridRow: `2`,
					display: "flex",
					alignItems: "center",
					justifyContent: "space-around",
				}}
			>
				<Typography>About</Typography>
				<Typography>Contact</Typography>
			</Box> */}
			{/* <Box
			// sx={{
			// 	height: "100%",
			// 	width: "100%",
			// 	display: "flex",
			// 	flexDirection: "column",
			// 	alignContent: "center",
			// 	justifyContent: "center",
			// }}
			>
				{isAuthenticated && user ? (
					<Button size="large" onClick={navigateToHome}>
						Enter
					</Button>
				) : (
					// <Button onClick={handleLogin}>Log In</Button>
					<Button size="large" variant="contained" onClick={handleLogin}>
						Log In
					</Button>
				)}
			</Box> */}
			{/* <Box
				sx={{
					width: "30%",
					height: "50%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					position: "relative",
					// filter: opacity('50%')

					"&::before": {
						content: '" "',
						backgroundColor: "white",
						borderRadius: 5,

						opacity: 0.5,
						position: "absolute",
						top: 0,
						right: 0,
						bottom: 0,
						left: 0,
					},
				}}
			> */}
			{/* </Box> */}
			{/* </Box> */}
		</motion.div>
	);
}
