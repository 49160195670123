
  export const toggleSnackbarOpen = (snackbar) => ({
    type: "TOGGLE_SNACKBAR_OPEN",
    snackbar,
   
  });
  
  export const toggleSnackbarClose = () => ({
    type: "TOGGLE_SNACKBAR_CLOSE",
  });
  
  