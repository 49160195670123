import { useAuth0 } from "@auth0/auth0-react";
import {
	Box,
	Button,
	Collapse,
	Drawer,
	Grow,
	List,
	ListItemButton,
	ListItemIcon,
	Typography,
	useMediaQuery
} from "@mui/material";
import { LayoutGroup, motion } from "framer-motion";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import { Colours, SidebarWidth } from "../../../assets/global/Theme-variable";
import LogoIcon from "../../../components/LogoIcon";
import UserBadge from "../../../components/UserBadge";
import { useUserState } from "../../../context/UserContext";
import AccessControl, { checkPermissions } from "../../../views/AccessControl";
import Menuitems from "./data";

const Sidebar = (props) => {
	const [open, setOpen] = React.useState(true);
	const { pathname } = useLocation();
	const pathDirect = pathname;
	const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
	const { logout, user, isAuthenticated } = useAuth0();
	const [active, setActive] = React.useState(null);
	const userPermission = useUserState();
	const [subMenu, setSubMenu] = React.useState([]);
	const navigate = useNavigate();

	React.useEffect(() => {
		if(props.isSidebarOpen === false || props.isMobileSidebarOpen === false) {
			setSubMenu([])
		}
	},[props.isSidebarOpen, props.isMobileSidebarOpen])

	const handleClick = (index) => {
		if (index === "Categories" || index === 'Admin') {
			if(!subMenu.includes(index)) {
			setSubMenu([...subMenu, index]);
			} else {
				setSubMenu(subMenu.filter((item) => item!== index));
			}

			setOpen(index);
		}  else if (open === index) {
			setOpen((prevopen) => !prevopen);
		} else {
			setOpen(index);
		}
	};

	const handleLogout = () => {
		logout({
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
	};

	const getAdminChildren = Menuitems.find((i)=> i?.dbName === 'admin')?.children
	const adminArr = []
	const checkAdmin = getAdminChildren.forEach((x)=> {
		if (checkPermissions(userPermission, {
		page: x.dbName === 'configuration' ? ['macros', 'paymentMethods','analysisCategories', 'modifiers','pricebands'] :  x.dbName === 'permissions' ? ['users', 'userTypes', 'policies'] : x.dbName ,
		strict: true,

		action: "view",
	})  === true) {adminArr.push(x)}})


	const SidebarContent = (
		<Box
			sx={{
				// p: 3,
				// height: "calc(100vh - 40px)",
				height: "100%",
				// width: "100%",

				// height: "90vh",
				// flex: 1,
				backgroundColor: "white",
				// margin: 2,
				mr: 0,
				// borderRadius: 5,
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				alignItems: "center",
				pt: 4,
				pb: 4,
			}}
		>
			{/* <Link to="/"> */}
			<Box
				sx={{
					display: "flex",
					alignItems: "Center",
					justifyContent: "center",
					// mt: 4,
					mb: 4,
				}}
				onClick={() => navigate("/")}
			>
				<LogoIcon width={140} />
			</Box>
			{/* </Link> */}


			<Box
				sx={{ mt: 0, height:'100%',width: "100%", justifySelf: "flex-start",		display:'flex',flexDirection:'column',			justifyContent: "space-between",
				}}
				// onClick={() => navigate("/profile")}
			>
							<List sx={{ width: "100%", flex: 1, justifyContent: "flex-start" }}>
				<LayoutGroup>
					{Menuitems.map((item, index) => {
						return (
							<AccessControl
								key={index}
								userPermissions={userPermission}
								unallowedPermissions={{
									page: item.dbName === 'admin' ? [ 'settings', 'users', 'userTypes', 'policies', 'macros', 'paymentMethods', 'analysisCategories', 'pricebands', 'modifiers']: item.dbName,
									// allow: false,
									action: "view",
									strict: item.admin === true,
									// strict: true
								}}
								renderNoAccess={() => <></>}
							>
								<React.Fragment key={index}>
									{item.dbName === 'admin' && item.children && adminArr.length === 1  ? (
											<motion.div
											layout
							
											transition={{ duration: 0.2 }}
											variants={{
												hidden: {},
												show: {
													transition: {
														staggerChildren: 0.1,
														delayChildren: 0.5,
													},
												},
											}}
											initial="hidden"
											animate="show"
											key={index}
										>
											<ListItemButton
												key={index}
												onClick={() => handleClick(index)}
												onMouseEnter={() => setActive(adminArr[0].href)}
												onMouseLeave={() => setActive(null)}
												component={NavLink}
												disableRipple
												to={adminArr[0].href}
												selected={pathDirect === adminArr[0].href}
												sx={{
													width: "100%",
													// ml: 1,
													...(pathDirect === adminArr[0].href && {
														color: "white",
														backgroundColor: (theme) =>
															`${Colours.white} !important`,
														width: "100%",
													}),
													backgroundColor: (theme) =>
														`white!important`,	
												
												}}
											>
												<ListItemIcon
													sx={{
														color:
															active === adminArr[0].href
																? Colours.gsblue
																: Colours.gsgrey,
														ml: 1,
														mr: 0.5,
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														...(pathDirect === adminArr[0].href && {
															color: Colours.gsblue,
														}),
													}}
												>
													<item.icon size={adminArr[0].iconSize} />
												</ListItemIcon>
												<Typography
													sx={{
														color:
															active === adminArr[0].href
																? Colours.gsblue
																: Colours.gsgrey,
														fontWeight: 200,
														fontSize: 14,
														...(pathDirect === adminArr[0].href && {
															color: Colours.gsblue,
														}),
													}}
												>
													{adminArr[0].title}
												</Typography>
											</ListItemButton>
										</motion.div>
									) : item.children && item.children.length > 1 ? (
										<React.Fragment key={index}>
											<ListItemButton
											disableRipple
												onClick={() => handleClick(item.mainTitle)}
												onMouseEnter={() => setActive(item.href)}
												onMouseLeave={() => setActive(null)}
												// selected={categoriesOpen}
												key={item}
												selected={
													pathDirect === item.href
												}
												sx={{
													width: "100%",
													// pb: 0,
													// ml: 1,
													...((pathDirect === item.href) && {
														backgroundColor: (theme) =>
															`${Colours.white}!important`,
													}),
													"&:hover": {
														backgroundColor: (theme) =>
															`white!important`,						},
													// ...((pathDirect === "/majorcategories" || pathDirect === "/admin" || pathDirect === "/configuration" || pathDirect === "/permissions") && {
													// 	backgroundColor: (theme) =>
													// 		`${Colours.white}!important`,
													// }),
												}}
											>
												<ListItemIcon
													sx={{
														color:
															active === item.href
																? Colours.gsblue
																: Colours.gsgrey,
														ml: 1,
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														mr: 0.5,
													}}
												>
													{subMenu.includes(item?.mainTitle) ?  (
														// Different icon when selected
														<item.selectedIcon size={item.iconSize} />
													) : (
														// Original icon when not selected
														<item.icon size={item.iconSize} />
													)}
												</ListItemIcon>
												<Typography
													sx={{
														color:
															active === item.href
																? Colours.gsblue
																: Colours.gsgrey,
														fontWeight: 200,
														fontSize: 14,
														userSelect: "none",
														cursor: "pointer",
													}}
												>
													{item.title}
												</Typography>
											</ListItemButton>

											{subMenu.includes(item?.mainTitle) ? (
												// <motion.div layout>
												<Collapse in={subMenu.includes(item?.mainTitle)} timeout="auto" unmountOnExit>
													<List
														sx={{  mt: 0, pt: 0, position: "relative" }}
													>
														{subMenu.includes(item?.mainTitle) && item.children.map((subItem, subIndex) => {
														return	(
															<AccessControl
															key={subIndex}
															userPermissions={userPermission}
															unallowedPermissions={{
																page: subItem.dbName === 'configuration' ? ['macros', 'paymentMethods','analysisCategories', 'modifiers','pricebands'] :  subItem.dbName === 'permissions' ? ['users', 'userTypes', 'policies'] : subItem.dbName ,
																// allow: false,
																action: "view",
																strict: item.admin === true,
															}}
															renderNoAccess={() => <></>}
														>
														        <Grow index={subIndex} in={subMenu.includes(item?.mainTitle)}  style={{ transformOrigin: '0 0 0' }}
																{...(subMenu.includes(item?.mainTitle) ? { timeout: 300*(subIndex +1) } : {})}>

														<ListItemButton
															disableGutters
															disableRipple
																key={subIndex}
																component={NavLink}
																to={subItem.href}
																selected={pathDirect === subItem.href}
																onMouseEnter={() => setActive(subItem.href)}
																onMouseLeave={() => setActive(null)}
																sx={{
																	color:
																		active === subItem.href
																			? Colours.gsblue
																			: Colours.gsgrey,
																	width: "100%",

																	alignItems: "flex-end",
																	justifyContent: "flex-start",
																	backgroundColor:'white !important',
																
																	padding: "0px 5px 5px 15px",
																	"&:hover": {
																		backgroundColor: (theme) =>
																			`white!important`,	},
																	position: "relative",
																	
																	"&::before, ::after": {
																		content: '""',
																		left: 40,
																		position: "absolute",
																		right: "auto",
																	},
																	"&::before": {
																		borderLeft: `1px solid ${Colours.gsgrey}`,
																		borderBottom: `1px solid ${Colours.gsgrey}`,
																		borderBottomLeftRadius: 5,
																		height: "114%",
																		marginTop: 1,

																		width: 15,
								
																	},

																	"& span": {
																		
																		position: "relative",
																		left: 45,
																		top: 8,
																		justifySelf:'flex-end',
																		display: "inline-block",
																		textDecoration: "none",
																	},

																	...(pathDirect === subItem.href && {
																		backgroundColor: (theme) =>
																			`white !important`,
																	}),
																}}
															>
																	
																<span>
																	<Typography
																		sx={{
																			color:
																				active === subItem.href
																					? Colours.gsblue
																					: Colours.gsgrey,
																			fontWeight: 200,
																			fontSize: 13,
																			...(pathDirect === subItem.href && {
																				color: Colours.gsblue,
																			}),
																			// pt:2
																		}}
																	>
																		{subItem.title}
																	</Typography>
																</span>
															</ListItemButton>
															</Grow>
															</AccessControl>

															)}

														)}
													</List>
												</Collapse>
											) : null}
										</React.Fragment>
									) : (
										<motion.div
											layout
							
											transition={{ duration: 0.2 }}
											variants={{
												hidden: {},
												show: {
													transition: {
														staggerChildren: 0.1,
														delayChildren: 0.5,
													},
												},
											}}
											initial="hidden"
											animate="show"
											key={index}
										>
											<ListItemButton
												key={index}
												onClick={() => handleClick(index)}
												onMouseEnter={() => setActive(item.href)}
												onMouseLeave={() => setActive(null)}
												component={NavLink}
												disableRipple
												to={item.href}
												selected={pathDirect === item.href}
												sx={{
													width: "100%",
													// ml: 1,
													...(pathDirect === item.href && {
														color: "white",
														backgroundColor: (theme) =>
															`${Colours.white} !important`,
														width: "100%",
													}),
													backgroundColor: (theme) =>
														`white!important`,	
												
												}}
											>
												<ListItemIcon
													sx={{
														color:
															active === item.href
																? Colours.gsblue
																: Colours.gsgrey,
														ml: 1,
														mr: 0.5,
														display: "flex",
														justifyContent: "center",
														alignItems: "center",
														...(pathDirect === item.href && {
															color: Colours.gsblue,
														}),
													}}
												>
													<item.icon size={item.iconSize} />
												</ListItemIcon>
												<Typography
													sx={{
														color:
															active === item.href
																? Colours.gsblue
																: Colours.gsgrey,
														fontWeight: 200,
														fontSize: 14,
														...(pathDirect === item.href && {
															color: Colours.gsblue,
														}),
													}}
												>
													{item.title}
												</Typography>
											</ListItemButton>
										</motion.div>
									)}
								</React.Fragment>
							</AccessControl>
						);
					})}
				</LayoutGroup>
			</List>
			<Box sx={{  width: "100%", flex: 1, display:'flex',flexDirection:'column',justifyContent: "flex-end", alignItems:'center'}}>
				{isAuthenticated && user && (
					<UserBadge
						handleLogout={handleLogout}
						// loggedInUser={user}
						token={props.token}
					/>
				)}
				<Button
				size="small"
				onClick={handleLogout}
				variant="gradient"
				sx={{ mt: 2, padding: "4px 9px" }}
				// endIcon={<BiChevronRight />}
			>
				Log Out
			</Button>
			</Box>
			</Box>

		</Box>
	);
	if (lgUp) {
		return (
			<Drawer
				anchor="left"
				open={props.isSidebarOpen}
				variant="persistent"
				PaperProps={{
					sx: {
						width: SidebarWidth,
						border: "none",
						// height: "100%",
						zIndex: 1,
						flex: 1,
					},
				}}
			>
				{SidebarContent}
			</Drawer>
		);
	}
	return (
		<Drawer
			anchor="left"
			open={props.isMobileSidebarOpen}
			onClose={props.onSidebarClose}
			PaperProps={{
				sx: {
					width: SidebarWidth,
					border: "none",
					// flex: 1,
					zIndex: 1,

					height: "100%",
					// height: "100vh",
				},
			}}
			variant="temporary"
		>
			{SidebarContent}
		</Drawer>
	);
};

export default Sidebar;
