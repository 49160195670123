import { Button, Paper, alpha } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { Colours } from "../../../assets/global/Theme-variable";

export default function AutoComplete({
	options,
	width,
	value,
	onChange,
	type,
	disabled,
	label,
	defaultValue,
	notListedNode,
	toggleOpen,
	mb,
}) {
	const selectedValues = React.useMemo(() =>
		type === "Item"
			? options.find((option) => option?.plu_number === value?.plu_number)
			: type === "Modifier"
			? options.find((option) => option?.number === value?.number)
			: type === "Navigate"
			? options.find((list) => list?.list_number === value?.list_number)
			: type === "List"
			? options.find((list) => list?.list_number === value?.list_number)
			: type === "Variant"
			? options.find((list) => list?.list_number === value?.list_number)
			: type === "Blank"
			? options[0].name
			: type === "Function"
			? options.find((func) => func?.function_number === value?.function_number)
			: type === "Payment Method"
			? options.find(
					(func) => func?.paymentMethod_number === value?.paymentMethod_number
			  )
			: type === "Macro"
			? options.find((func) => func?.macro_number === value?.macro_number)
			: type === "Preset Server"
			? options.find((func) => func?.value === value?.value)
			: type === "Control"
			? options.find((func) => func?.value === value?.value)
			: type === "rule"
			? options.find((func) => func?.dbName === value)
			: // : value,
			  value[options]
	);
	return (
		<Autocomplete
			id=""
			className="listSelect"
			sx={{
				width: width ? width : "100%",
				mb: mb,
				"& .MuiAutocomplete-option": {
					width: 200,
					fontSize: 500,
					color: Colours.blue,
				},
				"& .MuiOutlinedInput-root.Mui-focused": {
					boxShadow:
						origin === "datagrid"
							? "none"
							: `${alpha(Colours.gsblue, 0.25)} 0 0 0 0.2rem`,
					borderColor: origin === "datagrid" ? "none" : Colours.gsblue,
				},
			}}
			size="small"
			disabled={type === "Blank" || disabled}
			isOptionEqualToValue={(option, currentValue) => {
				return type === "Item"
					? parseInt(option?.plu_number) === parseInt(value?.plu_number)
					: type === "Navigate"
					? parseInt(option.list_number) === parseInt(value?.list_number)
					: type === "List"
					? parseInt(option.list_number) === parseInt(value?.list_number)
					: type === "Variant"
					? parseInt(option.list_number) === parseInt(value?.list_number)
					: type === "Macro"
					? parseInt(option.macro_number) === parseInt(value?.macro_number)
					: type === "Payment Method"
					? parseInt(option?.paymentMethod_number) ===
					  parseInt(value?.paymentMethod_number)
					: type === "Modifier"
					? parseInt(option?.number) === parseInt(value?.number)
					: type === "Function"
					? parseInt(option.function_number) ===
					  parseInt(value?.function_number)
					: type === "Preset Server"
					? parseInt(option.value) === parseInt(value?.value)
					: type === "Control"
					? parseInt(option.value) === parseInt(value?.value)
					: type === "Site"
					? parseInt(option.site_number) === parseInt(value?.site_number)
					: type === "rule"
					? option.page === value?.page
					: option.name === value?.name;
			}}
			options={options}
			autoHighlight
			PaperComponent={({ children }) => {
				return (
					<Paper
						sx={{
							"& .MuiAutocomplete-option": {
								fontSize: 16,
								color: Colours.darkGrey,
							},
						}}
					>
						{toggleOpen && (
							<div>
								<Button
									// color="primary"
									fullWidth
									sx={{
										justifyContent: "flex-start",
										mt: 0.5,
										width: "100%",
										// borderRadius: 0,
										"&:hover": {
											// backgroundColor: "white",
											// textDecoration: "underline",
											boxShadow: "none",
										},
									}}
									onMouseDown={toggleOpen}
								>
									+ ADD NEW
								</Button>
							</div>
						)}
						{children}
					</Paper>
				);
			}}
			getOptionLabel={
				(option) =>
					type === "Item"
						? `${option.plu_number} ${option.plu_name}`
						: type === "Navigate"
						? `${option.list_number} ${option.list_name}`
						: type === "List"
						? `${option.list_number} ${option.list_name}`
						: type === "Variant"
						? `${option.list_name}`
						: type === "Macro"
						? `${option.macro_number} - ${option.macro_name}`
						: type === "Payment Method"
						? `${option.paymentMethod_number} ${option.paymentMethod_name}`
						: type === "Modifier"
						? `${option.number} ${option.name}`
						: type === "Function"
						? `${option.function_number} ${option.function_name}`
						: type === "Preset Server"
						? `${option.value}`
						: type === "Control"
						? `${option.value} ${option.name}`
						: type === "Site"
						? `${option.site_number} ${option.site_name}`
						: type === "rule"
						? `${option.page}`
						: `${option}`
				// `${option.number} ${option.name}`
			}
			// renderOption={
			// 	(props, option) => (
			// 		// option.noOption === "true" ? (
			// 		// 	notListedNodes
			// 		// ) : (
			// 		<Box component="li" {...props} key={option}>
			// 			{type === "Item"
			// 				? `${option.plu_number} - ${option.plu_name}`
			// 				: type === "Navigate"
			// 				? `${option.list_number} - ${option.list_name}`
			// 				: type === "List"
			// 				? `${option.list_number} - ${option.list_name}`
			// 				: type === "Variant"
			// 				? `${option.list_name}`
			// 				: type === "Macro"
			// 				? `${option.macro_number} - ${option.macro_name}`
			// 				: type === "Payment Method"
			// 				? `${option.paymentMethod_number} - ${option.paymentMethod_name}`
			// 				: type === "Modifier"
			// 				? `${option.number} - ${option.name}`
			// 				: type === "Function"
			// 				? `${option.function_number} - ${option.function_name}`
			// 				: type === "Preset Server"
			// 				? `${option.value}`
			// 				: type === "Site"
			// 				? `${option.site_number} ${option.site_name}`
			// 				: type === "Control"
			// 				? `${option.value} - ${option.name}`
			// 				: type === "rule"
			// 				? `${option.page}`
			// 				: option}
			// 		</Box>
			// 	)
			// 	// )
			// }
			// defaultValue={defaultValue && defaultValue}
			aria-label={label}
			value={selectedValues || null}
			onChange={(event, newValue, option) => onChange(event, newValue)}
			renderInput={(params) => (
				<TextField
					// contentEditable={type !== "Blank"}
					{...params}
					label={label}
					inputProps={{
						...params.inputProps,
						autoComplete: "new-", // disable autocomplete and autofill
					}}
					sx={{}}
					// aria-label={label}
				/>
			)}
		/>
	);
}
