import { useDispatch, useSelector } from "react-redux";
import { toggleSnackbarClose } from "../redux/actions/snackbarActions";
import { Alert, Snackbar } from "@mui/material";

const SuccessSnackbar = () => {  const dispatch = useDispatch();

  const SNACKBAR = useSelector((state) => state.snackbar);

// console.log(useSelector((state) => state))
  function handleClose() {
    dispatch(toggleSnackbarClose());
  }

  return (
    <Snackbar
    open={SNACKBAR.open}
    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    onClose={handleClose}
    autoHideDuration={4000}
>
<Alert
    onClose={handleClose}
    severity={SNACKBAR.severity}
    // variant="filled"
    sx={{ width: '100%' }}
  >
{SNACKBAR.message}  </Alert></Snackbar>
  );
}
export default SuccessSnackbar;
