import {
	Box,
	Button,
	Card,
	Snackbar,
	Tab,
	Tabs,
	Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";

// import { DataGridContext } from "./datagrids/data grid files/DataGridContext";

import Spinner from "../components/Spinner/Spinner";

import { dataGridStyle } from "./datagrids/styles";

import { getSubField, setSubField, useRouteMatch } from "./datagrids/utils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DataGridRefactor from "./datagrids/DataGridRefactor/DataGrid";
import { PAGE_TABLE_MAPPING } from "../API/apiConfig";
import { Config } from "./datagrids/DataGridRefactor/Config";
import { DataGridContext } from "../context/DataGridContext";
import AccessControl, { checkPermissions } from "./AccessControl";
import { useUserState } from "../context/UserContext";

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

export default function Configuration() {
	const { data,  } =
		React.useContext(DataGridContext);
		const userPermission = useUserState()

	const routeMatch = useRouteMatch([
		"configuration/macros",
		"configuration/paymentMethods",
		"configuration/functions",
		"configuration/analysisCategories",
		"configuration/pricebands",
		"configuration/modifiers",
	]);
	const navigate = useNavigate();
	const currentTab = routeMatch?.pattern?.path;
	const path =  currentTab ? currentTab?.split("/")[1] : checkPermissions(userPermission, {
		page: 'macros',
		action: "view",
		strict: true,
	}) === true ? "macros" : checkPermissions(userPermission, {
		page: 'modifiers',
		action: "view",
		strict: true,
	}) === true ? "modifiers" : checkPermissions(userPermission, {
		page: 'analysisCategories',
		action: "view",
		strict: true,
	}) === true ? "analysisCategories" :  checkPermissions(userPermission, {
		page: 'pricebands',
		action: "view",
		strict: true,
	}) === true ? "pricebands" :'paymentMethods';
	const [type, setType] = React.useState(path);
	const [contextName, setContextName] = React.useState(
		path === "analysisCategories" ? "analysisCategory" : path.slice(0, -1)
	);
	const [value, setValue] = React.useState(
		type === "macros"
			? 0
			: type === "paymentMethods"
			? 1
			: type === "analysisCategories"
			? 2
			: type === "pricebands"
			? 3
			: 4
	);

	const handleChange = (event, newValue) => {
		setValue(newValue);

		let type = "";
		let context = "";

		switch (newValue) {
			case 0:
				type = "macros";
				context = "macro";
				break;
			case 1:
				type = "paymentMethods";
				context = "paymentMethod";
				break;
			case 2:
				type = "analysisCategories";
				context = "analysisCategory";
				break;
			case 3:
				type = "pricebands";
				context = "priceband";
				break;
			case 4:
				type = "modifiers";
				context = "modifier";
				break;

			default:
		}
		// console.log(context, type);
		setContextName(context);
		setType(type);
		navigate(`/configuration/${type}`);
	};

	const location = useLocation();
	const path2 = contextName;

	const [rows, setRows] = React.useState(
		
		[]
	);

	const page = location.pathname.substring(1);

	const table = PAGE_TABLE_MAPPING[contextName];

	let gridList = Config(contextName);
	// const getVisibleColumns = () => {
	// 	const visCol = {};
	// 	for (let i = 0; i < gridList.columns.length; i++) {
	// 		const field = gridList.columns[i].field;
	// 		const visible = gridList.columns[i].visible;
	// 		Object.assign(visCol, { [field]: visible });
	// 	}
	// 	return visCol;
	// };

	const [visibleColumns, setVisibleColumns] = React.useState(
		[]
	);
	

	const [modalConfig, setModalConfig] = React.useState({
		focusField: "",
		editRow: "",
	});
	const [importModalVisible, setImportModalVisible] = React.useState(false);
	const [modalVisible, setModalVisible] = React.useState(false);

	return (
		<>
			{data?.macro === null ||
			data?.paymentMethod === null ||
			data?.priceband === null ||
			data?.function === null ||
			data?.modifier === null ? (
				<Spinner />
			) : (
				<Box
					sx={{
						maxWidth: "100%",
						maxHeight: "100%",
						overflow: "hidden",
						display: "flex",
						flexDirection: "column",
						flex: 1,
						ml: 4,
						mr: 4,
					}}
				>
				
					<Box
						sx={{
							mb: 2,
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}
					>
						<Tabs
							value={value}
							onChange={handleChange}
							aria-label="basic tabs example"
						>
									<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: 'macros',
							action: "view",
							strict: true,
						}}
						renderNoAccess={() => <></>}
					>
							<Tab
								sx={{ paddingBottom: 0 }}
								label="Macros"
								{...a11yProps(0)}
								to={"/configuration/macros"}
								component={Link}
								value={0}
							/>
							</AccessControl>
							<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: 'paymentMethods',
							action: "view",
							strict: true,
						}}
						renderNoAccess={() => <></>}
					>
							<Tab
								sx={{ paddingBottom: 0 }}
								label="Payment Methods"
								{...a11yProps(1)}
								to={"/configuration/paymentMethods"}
								component={Link}
								value={1}
							/>
							</AccessControl>
							<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: 'analysisCategories',
							action: "view",
							strict: true,
						}}
						renderNoAccess={() => <></>}
					>
							<Tab
								sx={{ paddingBottom: 0 }}
								label="Analysis Categories"
								{...a11yProps(2)}
								to={"/configuration/analysisCategories"}
								component={Link}
								value={2}
							/>
							</AccessControl>
							<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: 'pricebands',
							action: "view",
							strict: true,
						}}
						renderNoAccess={() => <></>}
					>
							<Tab
								sx={{ paddingBottom: 0 }}
								label="Price Bands"
								{...a11yProps(3)}
								to={"/configuration/pricebands"}
								component={Link}
								value={3}
							/>
							</AccessControl>
							<AccessControl
						userPermissions={userPermission}
						unallowedPermissions={{
							page: 'modifiers',
							action: "view",
							strict: true,
						}}
						renderNoAccess={() => <></>}
					>
							<Tab
								sx={{ paddingBottom: 0 }}
								label="Modifiers"
								{...a11yProps(4)}
								to={"/configuration/Modifiers"}
								component={Link}
								value={4}
							/>
							</AccessControl>
						</Tabs>
					</Box>
				
					<Box
						role="tabpanel"
						sx={{
							...dataGridStyle,
							flex: 1,
							width: "100%",
							height: "80%",
							"& .MuiDataGrid-root": {
								border: "none",
								height: "100%",
								flex: 1,
								overflow: "auto",
								whiteSpace: "normal !important",
								wordWrap: "break-word !important",
								fontSize: 13,
								"& .MuiDataGrid-cell": {
									borderBottom: "none",
								},
							},
							"& .MuiDataGrid-cell": {
								borderBottom: "none",
							},
							mb: 2,
						}}
					>
						<DataGridRefactor
							gridList={gridList}
							page={page}
							modalVisible={modalVisible}
							setModalVisible={setModalVisible}
							modalConfig={modalConfig}
							setModalConfig={setModalConfig}
							rows={rows}
							setRows={setRows}
							visibleColumns={visibleColumns}
							setVisibleColumns={setVisibleColumns}
							importModalVisible={importModalVisible}
							table={table}
							contextName={path2}
							setImportModalVisible={setImportModalVisible}
						/>
					</Box>
				</Box>
			)}
		</>
	);
}
