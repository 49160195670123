import { Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import * as React from "react";

import { useUserState } from "../../../context/UserContext";
import AccessControl from "../../AccessControl";

export default function SelectWithAddOption({
	options,
	onChange,
	value,
	width,
	type,
	open,
	setOpen,
	label,
	marginRight,
	disabledAdd
}) {
	const userPermission = useUserState()

	const handleClickOpen = () => {
		setOpen(true);
	};

	return (
		<FormControl variant="outlined" sx={{ width: width, p: 0 }}>
			<InputLabel shrink id="label">
				{label}
			</InputLabel>
			<Select
				notched
				labelId="label"
				id="demo-multiple-checkbox"
				value={value ? value : ""}
				onChange={onChange}
				renderValue={(selected) => selected}
				options={options}
				size="small"
				sx={{ pr: 2, mr: marginRight && marginRight }}
				label={label}
				defaultValue="None"
				MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
			>
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: "screens",
						strict: false,
						action: "edit",
					}}
					renderNoAccess={() => <></>}
				>
					<MenuItem value={"buttonClick"}>
						<Button onClick={handleClickOpen} sx={{ mb: 0 }}>
							Add New
						</Button>
					</MenuItem>
				</AccessControl>
				{options &&
					options.map((option) => (
						<MenuItem key={option} value={option}>
							{type === "List"
								? `${option.list_number} ${option.list_name}`
								: option}
						</MenuItem>
					))}
			</Select>
		</FormControl>
	);
}
