import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, IconButton, InputAdornment, Menu, MenuItem, Modal, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ReactHookFormSelect from "../../../../components/ReactHookFormSelect";
import { DataGridContext } from "../../../../context/DataGridContext";
import { useRowsState } from "../../../../context/RowsContext";
import { toggleBulkPricingClose } from "../../../../redux/actions/bulkPricingActions";
import { getUpdatedDisplayPrice } from "../../DataGridUtils";
import { NumberFormatCustom } from "../../utils";
import ExamplePricingTable from "./ExamplePricingTable";
import { startTransition } from "react";


export const BulkPricingModal = React.forwardRef(function BulkPricingModal(
	props,
	ref
) {
    
  const {setIsSaving,isSaving,onSubmitBulkPriceChange, selectedRows, setSelectedRowsData, unsavedChangesRef, allIds} = props
      const dispatch = useDispatch();
  const MODAL = useSelector((state) => state.bulkPricing);
  const handleClose = () => {
    dispatch(toggleBulkPricingClose());
  }
  const {
    control,
    reset,
    handleSubmit,
    setValue,
    formState,
    watch,
    formState: { errors },
} = useForm({
    defaultValues: {percent: false, percent_amount: 0,increase_decrease: 'increase',fixed_amount: false,fixed_amount_number: 0, round_to: 0.05,round:false,round_direction: 'up' },
});

const watchPercentage = watch("percent");
const watchFixedAmount = watch("fixed_amount");
const watchRound = watch("round");
const percentAmount = watch("percent_amount");
const fixedAmount = watch("fixed_amount_number");
const roundDirection = watch("round_direction");
const increaseDecrease = watch("increase_decrease");
const roundTo = watch("round_to");
const rows = useRowsState();
const [loading, setLoading] = useState(false)
const [anchorEl, setAnchorEl] = useState(null);
const open = Boolean(anchorEl)

const onSubmit = () => startTransition(() => {
    handleCloseAnchor()
    setLoading(true)
})

const onSubmit2 = () => startTransition(() => {
setSelectedRowsData(ref.current.getAllRowIds()) 
    handleCloseAnchor()
    setLoading(true)
    });

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    
    const handleCloseAnchor = () => {
        setAnchorEl(null);
      };

React.useEffect(() => {
    setTimeout(()=> {
if (loading) {
    onSubmitBulkPriceChange(watchPercentage, watchFixedAmount, percentAmount, increaseDecrease, fixedAmount, watchRound, roundTo, roundDirection)
.then((res) => console.log(res))
.finally(() => {
    dispatch(toggleBulkPricingClose())
    setLoading(false)
}
)
}
},[1000])
}, [loading])



const roundingOptions = [
    {label: '5p', value: 0.05},
{label: '10p', value: 0.10}, 
    {label: '50p', value: 0.5},
    {label: '£1', value: 1}, 
]

  return (
    <Modal
    open={MODAL.open}
    onClose={handleClose}
>

<Box sx={style}>
<Box sx={style2}>
<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-between",
										alignItems: "center",
										height: "10%",
										pb: 2,
									}}
								>
									<Typography variant="h3">
										Bulk Pricing
									</Typography>
									<IconButton
										aria-label="close"
										sx={{ alignSelf: "flex-end" }}
										onClick={handleClose}
									>
										<CloseIcon />
									</IconButton>
                                    </Box>
                                    <FormControl component="fieldset">
  <Controller
    // rules={{ required: true }}
    control={control}
    name="increase_decrease"
    render={({ field }) => (
      <RadioGroup {...field} row         defaultValue="increase" sx={{width:'100%', display:'flex', alignItems:'center', justifyContent:'space-between'}}
>
        <FormControlLabel
          value="increase"
          control={<Radio />}
          label="Price increase"
        />
        <FormControlLabel
          value="decrease"
          control={<Radio />}
          label="Price reduction"
        />
      
      </RadioGroup>
    )}
  />
</FormControl>
                              <Box sx={{display:'flex',flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                  <Box>
                                    <Controller
				name="fixed_amount"
				control={control}
				render={
					({ field }) => {
						return (
							<FormControlLabel
								control={
									<Checkbox
										checked={field.value}
									/>
								}
								label={<Typography sx={{ fontSize: 14 }}>Fixed Amount</Typography>}
								{...field}
								onChange={(e, newValue) => {
									setValue(
										"fixed_amount",
										newValue
									);
								}}
							/>
						);
					}
				}
			/>
            </Box>
            <Controller
				name="percent"
				control={control}
				render={
					({ field }) => {
						return (
							<FormControlLabel
								control={
									<Checkbox
										checked={field.value}
									/>
								}
								label={<Typography sx={{ fontSize: 14 }}>Percent</Typography>}
								{...field}
								onChange={(e, newValue) => {
									setValue(
										"percent",
										newValue
									);
								}}
							/>
						);
					}
				}
			/>
             <Controller
				name="round"
				control={control}
				render={
					({ field }) => {
						return (
							<FormControlLabel
								control={
									<Checkbox
										checked={field.value}
									/>
								}
								label={<Typography sx={{ fontSize: 14 }}>Round?</Typography>}
								{...field}
								onChange={(e, newValue) => {
									setValue(
										"round",
										newValue
									);
								}}
							/>
						);
					}
				}
			/>
            </Box>
            <Box sx={{mt: 2,width:'100%',display:'flex',flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start', gap: 2}}>
            {watchPercentage && 
                     <Controller
					name={`percent_amount`}
					control={control}
					render={({ field: { name, onChange, onBlur, value, ref } }) => (
						<TextField
							label={"Percentage"}
							size="small"
							type="text"
							sx={{ width: "100%" }}
							InputLabelProps={{
								shrink: true,
							}}
							value={value}
							name={name}
							onChange={(event) => {
								onChange(parseInt(event.target.value));
							}}
							InputProps={{
								inputComponent: NumberFormatCustom,

								inputMode: "numeric",
								pattern: "[0-9]*",
								endAdornment: (
									<InputAdornment position="end">%</InputAdornment>
								),
							}}
						/>
					)}
					// rules={{
					// 	required: true,
					// }}
				/>}
            {watchFixedAmount &&
                                    <Controller
					name={`fixed_amount_number`}
					control={control}
					render={({ field: { name, onChange, onBlur, value, ref } }) => (
						<TextField
							label={"Fixed Amount"}
							size="small"
							type="text"
							sx={{ width: "100%" }}
							InputLabelProps={{
								shrink: true,
							}}
							value={value}
							name={name}
							onChange={(e) => {
                                    if (isNaN(e.target.value)) {
                                      onChange(0)
                                    } else {
                                      e.target.value = e.target.value >= 0 ? e.target.value : 0;
                                      onChange(parseFloat(e.target.value))
                                    }
                                							}}
							InputProps={{
								inputComponent: NumberFormatCustom,
                            
								inputMode: "numeric",
								pattern: "[0-9]*",
								startAdornment: (
									<InputAdornment position="start">£</InputAdornment>
								),
							}}
						/>
					)}
					// rules={{
					// 	required: true,
					// }}
				/>}
             
          
                 
            {watchRound && <Box sx={{width:'100%',display:'flex',flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
  <Controller
    rules={{ required: true }}
    control={control}
    name="round_direction"
    render={({ field }) => (
      <RadioGroup {...field} row         defaultValue="up" sx={{flex:1.5}}
>

        <FormControlLabel
          value="up"
          control={<Radio />}
          label={<Typography sx={{fontSize: 12}}>Round up</Typography>}
        />
        <FormControlLabel
          value="down"
          control={<Radio />}
          label={<Typography sx={{fontSize: 12}}>Round down</Typography>}
        />
      
      </RadioGroup>
    )}
  />
<Box sx={{width:'100%',flex:1}}>
            	<ReactHookFormSelect
						id="round_to"
						name="round_to"
						label="to nearest"
						control={control}
                        flex={200}
						errors={formState.errors}
						rules={{
							required: "Round to amount is required",
						}}
					>
						{roundingOptions?.map((opt) => (
							<MenuItem key={opt.label} value={opt.value}>
								{opt.label}
							</MenuItem>
						))}
					</ReactHookFormSelect>
					{/* {formState?.errors?.round_to && (
						<Typography sx={{ color: Colours.red, fontSize: 12 }}>
							{formState?.errors?.round_to?.message}
						</Typography>
					)} */}
                    </Box>
                    </Box>}
                    <Box sx={{width:'100%',flex:1}}>
                       <Typography>Example Price Calculation:</Typography> 
                        <ExamplePricingTable row={getUpdatedDisplayPrice(rows, unsavedChangesRef,selectedRows[0], watchPercentage, watchFixedAmount, percentAmount, increaseDecrease, fixedAmount, watchRound, roundTo, roundDirection)} />

</Box>
                                <Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
                        width:'100%'
					}}
				>
					<Button
						variant="outlined"
						size="medium"
						sx={{ alignSelf: "flex-end" }}
						onClick={() => reset()}
					>
						Reset form
					</Button>
					{/* <LoadingButton
						size="small"
						onClick={handleSubmit(onSubmit)}
						variant={ "contained"}
						loadingPosition="start"
                        loading={loading}
                        disabled={loading}
						startIcon={<SaveIcon />}
					>
						<span>Apply</span>
					</LoadingButton> */}
                    <Button
        loading={loading}
        disabled={loading}
        loadingPosition="start"
        endIcon={<SaveIcon />}
        variant="contained"
        // onClick={handleSubmit(onSubmit)}
        onClick={handleClick}


      >
        Apply
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseAnchor}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleSubmit(onSubmit)}>Apply to {selectedRows.length > 1 ? selectedRows.length + ' selected rows' : '1 selected row' }</MenuItem>
        <MenuItem onClick={handleSubmit(onSubmit2)}>Apply to All Rows</MenuItem>
      </Menu>

				</Box>
                {loading &&
                <Box sx={{display:'flex',gap:2, flexDirection:'row',alignItems:'center', justifyContent:'space-between'}}>
                 <CircularProgress disableShrink />
                <Typography>Updating prices, please do not close this window.</Typography>
                </Box>}
                </Box>
    </Box>
   </Box>

</Modal>
  );
})


const style2 = {
	height: "100%",
	width: "100%",

	p: 4,
	overflow: "hidden",
	display: "flex",
	justifyContent: "flex-start",
	// gap: 2,
	flexDirection: "column",

	overflowY: "scroll",

	"&::-webkit-scrollbar": {
		width: "3px",
	},
	"&::-webkit-scrollbar-track": {
		backgroundColor: "#f5f5f5",
	},
	"&::-webkit-scrollbar-thumb": {
		borderRadius: "10px",
		boxShadow: "inset 0 0 10px rgba(0,0,0,.3)",
		// backgroundColor: "#f5f5f5",
	},
	"& .MuiDataGrid-root": {
		border: "none",
		height: "100%",
		// width: "100%",
		flex: 1,
		overflow: "auto",
		whiteSpace: "normal !important",
		wordWrap: "break-word !important",
		fontSize: 13,
		// maxWidth: "100%",
	},
	"& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
		outline: "none !important",
	},
	"& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
		{
			outline: "none !important",
		},
	// "& .MuiDataGrid-footerContainer": {
	// 	height: "30px",
	// 	minHeight: "30px",
	// },
};

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "10px",
	width: 500,
	height: "auto",
	// height: "100%",
	// minHeight:
	// 	// 100 +
	// 	typeof modalColumns !== "undefined" ? modalColumns.length * 70 : 400,
	bgcolor: "white",
	boxShadow: 24,
	// p: 4,
	overflow: "auto",
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	// padding: 0,
	gap: 2,
	flexDirection: "column",
};
