import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import Spinner from "../../../components/Spinner/Spinner";
import { useAppContext } from "../../../appContext";
import { useMount } from "react-use";
import { PAGE_TABLE_MAPPING } from "../../../API/apiConfig";
import { Config } from "./Config";
import DataGridRefactor from "./DataGrid";
import { dataGridStyle } from "../styles";
import { DataGridContext } from "../../../context/DataGridContext";
// import whyDidYouRender from "@welldone-software/why-did-you-render";

// whyDidYouRender(React, {
// //   onlyLogs: true,
//   titleColor: "green",
//   diffNameColor: "darkturquoise"
// });

export default function GridPageRefactor() {
	const { data  } = React.useContext(DataGridContext);
	const [importModalVisible, setImportModalVisible] = useState(false);
	const [modalVisible, setModalVisible] = useState(false);
	const [modalConfig, setModalConfig] = useState({
		focusField: "",
		editRow: "",
	});

	const location = useLocation();
	const page =
		location.pathname.substring(1) === "pricing"
			? "items"
			: location.pathname.substring(1);

	const contextName = page;

	const table = PAGE_TABLE_MAPPING[page];

	const pageAdjust = location.pathname.substring(1);

	let gridList = Config(pageAdjust);

	const getVisibleColumns = () => {
		const visCol = {};
		for (let i = 0; i < gridList.columns.length; i++) {
			const field = gridList.columns[i].field;
			const visible = gridList.columns[i].visible;
			Object.assign(visCol, { [field]: visible });
		}
		return visCol;
	};

	const [showLoader, setLoader] = useState(true);
	const {
		setState,
		state: { tourActive },
	} = useAppContext();

	useMount(() => {
		if (tourActive) {
			setTimeout(() => {
				setLoader(false);
				setState({ run: true });
			}, 1200);
		}
	});

	const [visibleColumns, setVisibleColumns] = useState(getVisibleColumns());

	if (data[contextName] === null) return <Spinner />;
	return (
		<Box
			sx={{
				...dataGridStyle,

				flex: 1,
				// minHeight: "100%",
				width: "100%",

				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
				ml: 4,
				mr: 4,
				mt: 2,
				mb: 2,
				overflow: "hidden",
				"& .MuiDataGrid-root": {
					border: "none",
					height: "100%",
					// width: "100%",
					flex: 1,
					overflow: "auto",
					whiteSpace: "normal !important",
					wordWrap: "break-word !important",
					fontSize: 13,
					"& .MuiDataGrid-cell": {
						borderBottom: "none",
					},
					"& .priceMod": {
						padding: 0,
					},
					// maxWidth: "100%",
				},
				"& .MuiDataGrid-cell": {
					borderBottom: "none",
				},
				"& .priceMod": {
					padding: 0,
				},
			}}
		>
			<DataGridRefactor
				gridList={gridList}
				page={page}
				modalVisible={modalVisible}
				setModalVisible={setModalVisible}
				modalConfig={modalConfig}
				setModalConfig={setModalConfig}
				visibleColumns={visibleColumns}
				setVisibleColumns={setVisibleColumns}
				importModalVisible={importModalVisible}
				table={table}
				contextName={contextName}
				setImportModalVisible={setImportModalVisible}
			/>
		</Box>
	);
}

