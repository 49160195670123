  const initialState = {
    open: false,
    message: [],
duration: 3000,
severity: 'success',
  };
  
  export default function uiReducer(state = initialState, action) {
    switch (action.type) {
      case "TOGGLE_SNACKBAR_OPEN": {
        return {
          ...state,
          open: true,
          message: action.snackbar.message,
duration: 3000,
severity: action.snackbar.severity,
        };
      }
  
      case "TOGGLE_SNACKBAR_CLOSE": {
        return {
          ...state,
          open: false,
          message: state.message,
duration: 0,
severity: state.severity,
        };
      }
  
      default: {
        return state;
      }
    }
  }