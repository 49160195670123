export const getRows = (gridList, data, contextName,table,type) => {
    
    let rows=[]
    // console.log(data, contextName)
    switch (gridList.gridPage) {
        case "screens":
            rows = data[contextName]?.filter(
                (screen) =>
                    !screen.hasOwnProperty("list_variant") || screen.list_variant === 0
            );
            break;
        case "analysisCategory":
            rows = data[contextName]
                    ?.filter(
                        (cat) =>
                            cat.analysisCategory_number!== "0" && cat.analysisCategory_number!== "100"
                    )
                    .sort((a, b) => a[type] - b[type])
            
            break;
        case "pricebands":
            rows = data[contextName]
                    ?.filter((cat) => cat.priceband_number!== "1")
                    .sort((a, b) => a[type] - b[type])
            
            break;
            case "staff":
                rows = data[contextName]
                ?.filter((i) => i.server_number !== "419" && i.server_number !== 419)
                .sort((a, b) => a[type] - b[type])
                break;
            default: rows = data[contextName]
    }
    return rows?.filter((i)=> i[`${table}_isDeleted`] === 0)
}

export const getAllRows = (gridList, data, contextName,table,type) => {
    let rows;
    switch (gridList.gridPage) {
        case "screens":
            rows = data[contextName]?.filter(
                (screen) =>
                    !screen.hasOwnProperty("list_variant") || screen.list_variant === 0
            );
            break;
        case "analysisCategory":
            rows = data[contextName]
                    ?.filter(
                        (cat) =>
                            cat.analysisCategory_number!== "0" && cat.analysisCategory_number!== "100"
                    )
                    .sort((a, b) => a[type] - b[type])
            
            break;
        case "pricebands":
            rows = data[contextName]
                    ?.filter((cat) => cat.priceband_number!== "1")
                    .sort((a, b) => a[type] - b[type]).filter((i)=> i[`${table}_isDeleted`] === 0)
            
            break;
            case "staff":
                rows = data[contextName]
                ?.filter((i) => i.server_number !== "419" && i.server_number !== 419)
                .sort((a, b) => a[type] - b[type])
                break;
            default: rows = data[contextName]
    }
    return rows
}

function round50(x, increment, direction)
{
    if (direction === 'up') {
        return Math.ceil(x / increment) * (increment);
    } else 
    return Math.floor(x / increment) * (increment) ;
    
}

export const getUpdatedPrice = (rows, num, watchPercentage, watchFixedAmount, percentAmount, increaseDecrease, fixedAmount, watchRound, roundTo, roundDirection)=> {
    if (num) {
       let row = [...rows]?.find((row) =>
       parseInt(row?.uid) === parseInt(num)
    );
        if(watchPercentage && watchFixedAmount) {
        if(increaseDecrease=== 'increase') {
        row.plu_details.price_update = (parseFloat(row.plu_details.price) * (1 + (percentAmount / 100 ))) + fixedAmount
        } else {
            row.plu_details.price_update = (parseFloat(row.plu_details.price) * (1 - (percentAmount / 100 )) ) - fixedAmount
        }
    }
    if(!watchPercentage && watchFixedAmount) {
        if(increaseDecrease === 'increase') {
        row.plu_details.price_update = parseFloat(row.plu_details.price) + fixedAmount
        } else {
            row.plu_details.price_update = parseFloat(row.plu_details.price) - fixedAmount
        }
   
    }
    if(watchPercentage&& !watchFixedAmount) {
                if(increaseDecrease === 'increase') {
        row.plu_details.price_update = parseFloat(row.plu_details.price) * (1 + (percentAmount / 100))
        } else {
            row.plu_details.price_update = parseFloat(row.plu_details.price) * (1 - (percentAmount / 100))
        }
    }

        if(watchRound === true) {
            row.plu_details.price_update = round50(row.plu_details.price_update, roundTo, roundDirection)

        }
        return row
    }
}

export const getUpdatedDisplayPrice = (rows, unsavedChangesRef, num, watchPercentage, watchFixedAmount, percentAmount, increaseDecrease, fixedAmount, watchRound, roundTo, roundDirection)=> {
    if (num) {
       const oldRow = unsavedChangesRef.current.unsavedRows[num] ?   unsavedChangesRef.current.unsavedRows[num] : rows?.find((row) =>
       parseInt(row?.uid) === parseInt(num)
    );
    if (oldRow) {
        const row = {...oldRow}
let price = row?.plu_details?.price
        if(watchPercentage && watchFixedAmount) {
        if(increaseDecrease=== 'increase') {
            price = (parseFloat(row.plu_details.price)  * (1 + ((isNaN(percentAmount) ? 0 : percentAmount))  / 100 + (isNaN(fixedAmount) ? 0 : fixedAmount)))
        } else {
            price= (parseFloat(row.plu_details.price) * (1 - ((isNaN(percentAmount) ? 0 : percentAmount)) / 100 - (isNaN(fixedAmount) ? 0 : fixedAmount))  )
        }
    }
    if(!watchPercentage && watchFixedAmount) {
        if(increaseDecrease === 'increase') {
            price = parseFloat(row.plu_details.price) + (isNaN(fixedAmount) ? 0 : fixedAmount)
        } else {
            price = parseFloat(row.plu_details.price) - (isNaN(fixedAmount) ? 0 : fixedAmount)
        }
   
    }
    if(watchPercentage&& !watchFixedAmount) {

                if(increaseDecrease === 'increase') {
                    price= parseFloat(row.plu_details.price) * (1 + ((isNaN(percentAmount) ? 0 : percentAmount)  / 100))
        } else {
            price = parseFloat(row.plu_details.price) * (1 - ((isNaN(percentAmount) ? 0 : percentAmount)  / 100))
        }
    }

        if(watchRound === true) {
            price = round50(price, roundTo, roundDirection)

        }
        const obj = {
        old: row?.plu_details?.price,
        new:  price >= 0 ? price : 0,
        difference: price >= 0 ? price- row?.plu_details?.price : (row?.plu_details?.price - 0)*-1,
    plu_name: row?.plu_name
        }

        return obj
        }
    }
}


export const getUpdatedModPrice = (oldPrice, watchPercentage, watchFixedAmount, percentAmount, increaseDecrease, fixedAmount, watchRound, roundTo, roundDirection)=> {
  
let price = oldPrice
        if(watchPercentage && watchFixedAmount) {
        if(increaseDecrease=== 'increase') {
            price = (parseFloat(oldPrice) + fixedAmount) * (1 + (percentAmount / 100 ))
        } else {
            price= (parseFloat(oldPrice) - fixedAmount) * (1 - (percentAmount / 100 ))
        }
    }
    if(!watchPercentage && watchFixedAmount) {
        if(increaseDecrease === 'increase') {
            price = parseFloat(oldPrice) + fixedAmount
        } else {
            price = parseFloat(oldPrice) - fixedAmount
        }
   
    }
    if(watchPercentage&& !watchFixedAmount) {

                if(increaseDecrease === 'increase') {
                    price= parseFloat(oldPrice) * (1 + (percentAmount / 100))
        } else {
            price = parseFloat(oldPrice) * (1 - (percentAmount / 100))
        }
    }

        if(watchRound === true) {
            price = round50(price, roundTo, roundDirection)

        }
        
        if (isNaN(price)) {
return 0        } else 
        return price >= 0 ? price : 0;
 
}
