import { useAuth0 } from "@auth0/auth0-react";
import SyncIcon from "@mui/icons-material/Sync";
import { LoadingButton } from "@mui/lab";
import {
	Button,
	FormControlLabel,
	Menu,
	Switch,
	Tooltip,
	Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import {
	GridCsvExportMenuItem,
	GridPrintExportMenuItem,
	GridToolbarContainer
} from "@mui/x-data-grid";
import RestoreIcon from '@mui/icons-material/Restore';
import SaveIcon from '@mui/icons-material/Save';
import axios from "axios";
import { motion } from "framer-motion";
import * as React from "react";
import { useDispatch } from "react-redux";
import { API_ENDPOINTS } from "../../../../API/apiConfig";
import RouterBreadcrumbs from "../../../../components/Breadcrumbs";
import CustomSearch from "../../../../components/CustomSearch";
import { useLoggedInUserState, useUserState } from "../../../../context/UserContext";
import { toggleBulkPricing } from "../../../../redux/actions/bulkPricingActions";
import { toggleSnackbarOpen } from "../../../../redux/actions/snackbarActions";
import AccessControl from "../../../AccessControl";
import PricingFilterPricebands from "../../../grid/components/PricingFilterPricebands";
import PricingFilterSites from "../../../grid/components/PricingFilterSites";

export default function CustomToolbar({
	setModalVisible,
	setModalConfig,
	setImportModalVisible,
	importModalVisible,
		gridList,
	searchValue,
	showDeleted,
	handleSearchValueChange,
	handleClearSearchValueChange,
	exportFields,
	setNewItemModalOpen,
	NewItemModalOpen,
	toggleShowDeleted,
	hasUnsavedRows,
	table,
	saveChanges,
	discardChanges,
	selectedRowsData,
	unsavedChangesRef,
	isSaving,
	discardingChanges
}) {

		const loggedInUser= useLoggedInUserState();
		const userPermission = useUserState()
const dispatch = useDispatch()

	const csvOptions = {
		fileName: `${gridList.gridPage}`,
		// delimiter: ";",
		delimiter: ",",
		utf8WithBom: true,
		fields: exportFields,
	};

	const printOptions = {
		fileName: `${gridList.gridPage}`,
		allColumns: false,
		fields: exportFields,
		hideFooter: true,
		hideToolbar: true,
	};

	const [anchorElMenu, setAnchorElMenu] = React.useState(null);
	const openMenu = Boolean(anchorElMenu);

	const showExport = () => {
		if (gridList.allowExport === true) {
			return (
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: gridList.gridPage,
						action: "export",
						strict: false,
						// strict:  gridList.gridPage === 'modifiers'  || gridList.gridPage === 'analysisCategories'  || gridList.gridPage === 'macros'  || gridList.gridPage === 'pricebands' || gridList.gridPage === 'paymentMethods' || gridList.gridPage === 'permissions' || gridList.gridPage === 'configuration'  || gridList.gridPage === 'users' || gridList.gridPage === 'userTypes' || gridList.gridPage === 'policies'  ? true : false,
					}}
					renderNoAccess={() => <></>}
				>
					<Button
						variant="outlined"
						size="medium"
						onClick={(event) => {
							setAnchorElMenu(event.currentTarget);
						}}
						className="exportButton"
					>
						Export
					</Button>
				</AccessControl>
			);
		} else {
			return null;
		}
	};

	const openNewModal = () => {
		setModalConfig({ focusField: "", editRow: {}, isNew: true });
		setModalVisible(true);
	};

	const showModalButton = () => {
		if (gridList.allowModalButton === true) {
			return (
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: gridList.gridPage,
						action: "create",
						// strict: false,
						strict:  gridList.gridPage === 'modifiers'  || gridList.gridPage === 'analysisCategories'  || gridList.gridPage === 'macros'  || gridList.gridPage === 'pricebands' || gridList.gridPage === 'paymentMethods' || gridList.gridPage === 'permissions' || gridList.gridPage === 'configuration'  || gridList.gridPage === 'users' || gridList.gridPage === 'userTypes' || gridList.gridPage === 'policies'  ? true : false,
					}}
					renderNoAccess={() => <></>}
				>
					<Button
						className="new"
						variant="gradient"
						size="medium"
						onClick={openNewModal}
					>
						{gridList.modalButtonTitle}
					</Button>
				</AccessControl>
			);
		} else {
			return null;
		}
	};
	
	const showMakeDeletedVisibleButton = () => {

		if (gridList.gridPage !== "pricing") {
			return (
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: gridList.gridPageAlt ?  gridList.gridPageAlt : gridList.gridPage,
						action: "undelete",
						strict:  true,
					}}
					renderNoAccess={() => <></>}
				>
					
					<FormControlLabel
						sx={{fontSize: 10}}
						control={
							<Switch
								size="small"
								onChange={toggleShowDeleted}
								checked={showDeleted }
							/>

						}
						label={<Typography sx={{fontSize:14, ml: 1}}>Include deleted</Typography>}
						labelPlacement="start"
						// sx={{ width: 00 }}
					/>
				</AccessControl>
			);
		}
	};

	const showImportButton = () => {
		if (gridList.allowImport) {
			return (
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: gridList.gridPage,
						action: "create",
						strict:  gridList.gridPage === 'modifiers'  || gridList.gridPage === 'analysisCategories'  || gridList.gridPage === 'macros'  || gridList.gridPage === 'pricebands' || gridList.gridPage === 'paymentMethods' || gridList.gridPage === 'permissions' || gridList.gridPage === 'configuration'  || gridList.gridPage === 'users' || gridList.gridPage === 'userTypes' || gridList.gridPage === 'policies'  ? true : false,
					}}
					renderNoAccess={() => <></>}
				>
					<Button
						variant="outlined"
						size="medium"
						onClick={() => setImportModalVisible(!importModalVisible)}
						className="importButton"
					>
						Import
					</Button>
				</AccessControl>
			);
		}
	};

	const showNewItemModalButton = () => {
		if (
			gridList.gridPage ===
			"items - something else so it doesn't load the newModal"
		) {
			return (
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: gridList.gridPage,
						action: "create",
						// strict: false,
						strict:  gridList.gridPage === 'modifiers'  || gridList.gridPage === 'analysisCategories'  || gridList.gridPage === 'macros'  || gridList.gridPage === 'pricebands' || gridList.gridPage === 'paymentMethods' || gridList.gridPage === 'permissions' || gridList.gridPage === 'configuration'  || gridList.gridPage === 'users' || gridList.gridPage === 'userTypes' || gridList.gridPage === 'policies'  ? true : false,
					}}
					renderNoAccess={() => <></>}
				>
					<Button
						variant="gradient"
						size="medium"
						// sx={{
						// 	":hover": {
						// 		backgroundColor: "#fff",
						// 		color: "#3c52b2",
						// 	},
						// }}
						onClick={() => setNewItemModalOpen(!NewItemModalOpen)}
					>
						New Item
					</Button>
				</AccessControl>
			);
		} else {
			return null;
		}
	};

const handleToggleBulk = () => {
	dispatch(toggleBulkPricing())
}


const showBulkPricingButton = () => {
		if (
			gridList.gridPage === 'pricing' && selectedRowsData.length > 0
		) {
			return (
				<AccessControl
					userPermissions={userPermission}
					unallowedPermissions={{
						page: gridList.gridPage,
						action: "bulkPriceEdit",
						strict:  true,
					}}
					renderNoAccess={() => <></>}
				>
						<motion.div
											layout
							
											initial={{ opacity: 0, scale: 0 }}
											animate={{ opacity: 1, scale: 1 }}
											transition={{
												duration: 0.4,
												// scale: { type: "spring",  },
											}}
											// initial="hidden"
											// animate="show"
											key={'save bulk changes'}
										>
					<Button
						variant="gradient"
						size="medium"
						onClick={handleToggleBulk}
					>BULK PRICING</Button>
									</motion.div>

				</AccessControl>
			);
		} 
	};

	const showBulkPricingOptions = () => {
		if (
			gridList.gridPage === 'pricing' && Object.values(
				unsavedChangesRef?.current?.unsavedRows,
			  )?.length > 0
		) {
		return (
			<>
			<motion.div
											layout
							
											initial={{ opacity: 0, scale: 0 }}
											animate={{ opacity: 1, scale: 1 }}
											transition={{
												duration: 0.4,
												// scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
											}}
											// initial="hidden"
											// animate="show"
											key={'save changes'}
										>
<LoadingButton
			onClick={saveChanges}
			loading={isSaving}
												variant={"outlined"}
												loadingPosition="center"
												disabled={!hasUnsavedRows || isSaving}
												sx={{ height: "100%" }}
												size='small'
												className='saveEdits'
											>
												<SaveIcon />
											</LoadingButton>
</motion.div>
<motion.div
											layout
							
											transition={{ duration: 0.2 }}
											variants={{
												hidden: {},
												show: {
													transition: {
														staggerChildren: 0.1,
														delayChildren: 0.5,
													},
												},
											}}
											initial="hidden"
											animate="show"
											key={'discard changes'}
										>
		<LoadingButton
			onClick={discardChanges}
			loading={discardingChanges}
												variant={"outlined"}
												loadingPosition="center"
												disabled={!hasUnsavedRows || discardingChanges}
												sx={{ height: "100%" }}
												size='small'
												className='saveEdits'
											>
<RestoreIcon />												</LoadingButton>
</motion.div>
		  </>
		)
	}
	}
	const { getAccessTokenSilently } = useAuth0();
	const [isSyncing, setIsSyncing] = React.useState(false);

	const syncMarketMan = async () => {
		setIsSyncing(true);
		let postStatus = 0;

		const token = await getAccessTokenSilently();

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		axios
			.get(
				`${gridList.gridpage === 'items' ? API_ENDPOINTS["marketManPLU"] : API_ENDPOINTS['marketManGroups']}`,

				config
			)
			.then(function (response) {
				postStatus = response.status;
				if (postStatus === 200) {
					dispatch(toggleSnackbarOpen({
						children: "successfully synced with MarketMan",
						severity: "success",
						open: true
					}));
					setIsSyncing(false);
				}
			})
			.catch(function (error) {
				setIsSyncing(false);

				postStatus = error.response.status;
				console.log(error, postStatus);
				dispatch(toggleSnackbarOpen({
					children: "Sync failed",
					severity: "success",
					open: true
				}));
			});
	};
// console.log(gridList.gridPage)
	const showMarketManSync = () => {
		if (
			// (
				loggedInUser?.app_metadata?.MMintegration === true 
				// || loggedInUser?.app_metadata?.company_ref === 22 || loggedInUser?.app_metadata?.company_ref === '22') 
				 &&(
				gridList.gridPage === "items" || gridList.gridPage === "majorcategories")
		) {
			const variants = {
				rotate: { rotate: 360, transition: { duration: 2, repeat: Infinity } },
				
			};

			return (
				<Tooltip title={"Sync with MarketMan"}>
					<Button
						variant="gradient"
						size="medium"
						// sx={{
						// 	":hover": {
						// 		backgroundColor: "#fff",
						// 		color: "#3c52b2",
						// 	},
						// }}
						// startIcon={<SyncIcon />}
						disabled={isSyncing}
						onClick={syncMarketMan}
					>
						<motion.div
						
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							variants={variants}
							animate={isSyncing ? "rotate" : "stop"}
						>
							<Box sx={{ height: 25, width: 25 }}>
								<SyncIcon />
							</Box>
						</motion.div>
					</Button>
				</Tooltip>
			);
		}
		return null;
	};

	const showPriceBandPageFilters = () => {
		if (gridList.gridPage === "pricing") {
			return <PricingFilterPricebands />;
		}
		// Return null if conditions are not met
		return null;
	};
	const showSitePageFilters = () => {
		if (gridList.gridPage === "pricing") {
			return <PricingFilterSites />;
		}
		// Return null if conditions are not met
		return null;
	};

	return (
		<GridToolbarContainer>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					mb: 2,
					alignItems: "center",
					// padding: 2,
					width: "100%",
					gap: 2,
				}}
			>
				<RouterBreadcrumbs />

				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						alignItems: "center",
						// padding: 2,
						width: "100%",
						gap: 5,
					}}
				>
					{gridList.gridPage === 'pricing' &&
					<Box sx={{display:'flex', justifyContent:'center', alignItems:'center', gap:2}}>
					{showBulkPricingOptions()}
					{showBulkPricingButton()}
					</Box>
					}
										<Box sx={{display:'flex', justifyContent:'center', alignItems:'flex-end', gap:2}}>
					{showSitePageFilters()}
					{showPriceBandPageFilters()}
					{showMakeDeletedVisibleButton()}
					<CustomSearch
						value={searchValue}
						onChange={handleSearchValueChange}
						onClear={handleClearSearchValueChange}
					/>
					{showImportButton()}
					{showMarketManSync()}
					{showExport()}
					{showModalButton()}
					{showNewItemModalButton()}
					
</Box>
				</Box>
			</Box>

			<Menu
				id="menu-options"
				anchorEl={anchorElMenu}
				open={openMenu}
				onClose={() => {
					setAnchorElMenu(null);
				}}
			>
				<GridCsvExportMenuItem options={csvOptions} />
				<GridPrintExportMenuItem options={printOptions} />
				{/* <JsonExportMenuItem /> */}
			</Menu>
		</GridToolbarContainer>
	);
}
