import React from "react";
import { Avatar, Box, Typography } from "@mui/material";
import { Colours } from "../assets/global/Theme-variable";
import { useNavigate } from "react-router-dom";
import { useLoggedInUserState } from "../context/UserContext";

const UserBadge = ({ token, handleLogout }) => {
	const navigate = useNavigate();
	const loggedInUser= useLoggedInUserState();

	return (
			<Box
				sx={{
					width: "100%",
					justifySelf: "flex-end",
					justifyContent: "center",
					alignItems: "center",
					display: "flex",
					flexDirection: "row",
				}}
				onClick={() => navigate("/profile")}
			>
				<Avatar
					src={
						loggedInUser?.picture
							? loggedInUser?.picture
							: "https://max-cloud-images.s3-eu-west-1.amazonaws.com/place-holder.png"
					}
					alt={loggedInUser?.name ? loggedInUser?.name : null}
					sx={{
						width: 40,
						height: 40,
						mr: 1,
						ml: 2,
					}}
				/>
				<Box
					sx={{
						mr: 2,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
					}}
				>
					<Typography
						variant="h5"
						sx={{
							color: Colours.gsblue,
							fontSize: 14,
							lineHeight: 1,
							userSelect: "none",
						}}
					>
						{loggedInUser?.name && loggedInUser?.name?.length > 10 ? loggedInUser?.nickname : loggedInUser?.name}
					</Typography>
					<Typography
						variant="h5"
						sx={{
							color: Colours.gsgrey,
							fontSize: 14,
							lineHeight: 1,
							userSelect: "none",
							mt: 0.5,
						}}
					>
						{loggedInUser?.app_metadata?.company_name ? loggedInUser?.app_metadata?.company_name : null}
					</Typography>
				</Box>
			</Box>
	);
};

export default UserBadge;
