import React from "react";
// import { DataGridContext } from "./datagrids/data grid files/DataGridContext";
import Spinner from "../components/Spinner/Spinner";
import ListDesignerRefactor from "./DesignerRefactor";
import AccessControl from "./AccessControl";
import NoPermission from "./NoPermission";
import { DataGridContext } from "../context/DataGridContext";
import { useUserState } from "../context/UserContext";

export default function DesignerPage() {
	const { data } = React.useContext(DataGridContext);
	const userPermission = useUserState()

	if (data?.screens === null) return <Spinner />;

	return (
		<AccessControl
			userPermissions={userPermission}
			unallowedPermissions={{
				page: "screens",
				// allow: false,
				strict: false,

				action: "view",
			}}
			renderNoAccess={() => (
				<NoPermission dbName={"list"} text=" edit screens" />
			)}
		>
			<ListDesignerRefactor />
		</AccessControl>
	);
}
