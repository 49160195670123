import React, { useState, useEffect, useContext } from "react";
import {
	createBrowserRouter,
	createHashRouter,
	RouterProvider,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { baseTheme } from "./assets/global/Theme-variable";
import { CssBaseline, Typography } from "@mui/material";
import ThemeRoutes from "./routes/Router";

import { getConfig } from "./views/profile/config";

import FullLayout from "./layouts/FullLayout/FullLayout";
import { linkContext } from "./views/profile/context";
import { Auth0ProviderWithNavigate } from "./Auth0Provider";

import { MyFallbackComponent } from "./views/FallBackComponent";
import { ErrorBoundary } from "react-error-boundary";
import { AppProvider } from "./appContext";
import CursorProvider from "./components/CursorProvider";
import { UserContextProvider } from "./context/UserContext";
import Main from "./Main";
import SnackbarContext from "./context/SnackbarContext";

const App = () => {
	const theme = baseTheme;
	const [someKey, setSomeKey] = React.useState(null);
	const [snackbar, setSnackbar] = React.useState({
		open: false,
		message: [],
		duration: 3000,
		severity: "success",
	})

		useEffect(() => {
		window.addEventListener("error", (e) => {
			if (e.message === "ResizeObserver loop limit exceeded") {
				const resizeObserverErrDiv = document.getElementById(
					"webpack-dev-server-client-overlay-div"
				);
				const resizeObserverErr = document.getElementById(
					"webpack-dev-server-client-overlay"
				);
				if (resizeObserverErr) {
					resizeObserverErr.setAttribute("style", "display: none");
				}
				if (resizeObserverErrDiv) {
					resizeObserverErrDiv.setAttribute("style", "display: none");
				}
			}
		});
	}, []);

	const config = getConfig();
	const mainProviderConfig = {
		domain: config.domain,
		// prompt: log
		clientId: config.clientId,
		authorizationParams: {
			redirect_uri: `${window.location.origin}`,
			skipRedirectCallback: window.location.href.includes("?secondary"),
			scope:
				"openid email profile offline_access read_all write_all read:current_user			update:current_user update:current_user_metadata update:current_user_identities",
			...(config.audience ? { audience: config.audience } : null),
		},
		useRefreshTokens: true,
		useRefreshTokensFallback: true,
		cacheLocation: "localstorage",
		responseType: "token id_token",
	};

	const linkProviderConfig = {
		domain: config.domain,
		clientId: config.clientId,
		context: linkContext,
		authorizationParams: {
			scope: "openid email profile",
			redirect_uri: `${window.location.origin}?secondary`,
			skipRedirectCallback: window.location.href.includes("?primary"),
			audience: `MaxCloud`,
		},
		// cacheLocation: "localstorage",
		useRefreshTokens: true,
		responseType: "token id_token",
	};

	const Auth0ProviderWithLayout = () => (
		<ErrorBoundary
			FallbackComponent={MyFallbackComponent}
			onReset={() => setSomeKey(null)}
			onError={logErrorToService}
			resetKeys={[someKey]}
		>
			<Auth0ProviderWithNavigate
				mainProviderConfig={mainProviderConfig}
				linkProviderConfig={linkProviderConfig}
			>
				{/* <UserContextProvider>
				<FullLayout />
				</UserContextProvider> */}
				<Main/>
			</Auth0ProviderWithNavigate>
		</ErrorBoundary>
	);

	const Routes = [
		{
			element: <Auth0ProviderWithLayout />,
			children: ThemeRoutes,
		},
	];

	// const router = createBrowserRouter(Routes, {
	// 	basename: "/",
	// });

	const router = createHashRouter(Routes);

	function logErrorToService(error, info) {
		console.log("Caught an error:", error, info);
	}
	return (
		<CssBaseline>
			<ThemeProvider theme={theme}>
				<AppProvider>
					<CursorProvider>
					<SnackbarContext.Provider value={{snackbar, setSnackbar}}>
					<UserContextProvider>


						<RouterProvider router={router} />
						</UserContextProvider>
</SnackbarContext.Provider>
					</CursorProvider>
				</AppProvider>
			</ThemeProvider>
		</CssBaseline>
	);
};

export default App;
