import React, { createContext, useCallback, useState } from "react";
const _ = require('lodash');

const RowStateContext = createContext()
const RowUpdaterContext = createContext()

function RowsContextProvider(props) {
const [rows, setRows] = useState([])

    return (
      <RowStateContext.Provider value={rows}>
        <RowUpdaterContext.Provider value={setRows}>
          {props.children}

        </RowUpdaterContext.Provider>
      </RowStateContext.Provider>
    )
  }

  function useRowsState() {
    const rowsState = React.useContext(RowStateContext)
    if (typeof rowsState === 'undefined') {
      throw new Error('rowState must be used within a rowStateProvider')
    }
    return rowsState
  }


  function useRowStateUpdater(rows) {
    const rowState = React.useContext(RowStateContext)
    const setRowState = React.useContext(RowUpdaterContext)

    const setRows = useCallback((rows) => { 
        if (  !_.isEqual(rows,rowState) ) {
            setRowState(rows)
		}
        	    },[setRowState, rowState ])

   
    return setRows
  }

export {RowsContextProvider, useRowsState,useRowStateUpdater,  }
