import React from "react";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";


import {
	AppBar,
	Box,
	IconButton,
	Toolbar
} from "@mui/material";


const Header = (props) => {
	return (
		<AppBar sx={props.sx} elevation={0} className={props.customClass}>
			<Toolbar sx={{ p: 0 }}>
				<IconButton
					color="inherit"
					aria-label="menu"
					onClick={props.toggleMobileSidebar}
					sx={{
						display: {
							lg: "none",
							xs: "inline",
						},
					}}
				>
					<MenuOutlinedIcon width="20" height="20" />
				</IconButton>
				<Box flexGrow={1} />
			</Toolbar>
		</AppBar>
	);
};

export default Header;
