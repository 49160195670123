import React, { useCallback, useState } from "react";
import {
	experimentalStyled,
	useMediaQuery,
	Container,
	Box,
	Typography,
} from "@mui/material";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";
import Footer from "./Footer/Footer";
import { Outlet, useNavigate } from "react-router-dom";
import Joyride, {
	ACTIONS,
	CallBackProps,
	EVENTS,
	STATUS,
	Step,
} from "react-joyride";
import { useMount } from "react-use";

import { Colours, TopbarHeight } from "../../assets/global/Theme-variable";
import { useAuth0 } from "@auth0/auth0-react";
import { MyFallbackComponent } from "../../views/FallBackComponent";
import LandingPage from "../../views/Landing";
import { DataGridContextProvider } from "../../context/DataGridContext";
import Spinner from "../../components/Spinner/Spinner";
import { CustomSnackBar } from "../../components/Snackbar";
import SnackbarContext from "../../context/SnackbarContext";
import { useAppContext } from "../../appContext";
import BakingContext from "../../context/BakingContext";
import { useBaking } from "../../context/useBaking";
import axios from "axios";
import { APP_URL } from "../../API/apiConfig";
import { useLoggedInUserState, UserContextProvider, useUserState, useUserUpdater } from "../../context/UserContext";
import { NoPermissionDialog } from "../../components/NoPermissionDialog";
// import whyDidYouRender from "@welldone-software/why-did-you-render";
import { RowsContextProvider } from "../../context/RowsContext";
import SuccessSnackbar from "../../components/SuccessSnackbar";
import { toggleSnackbarOpen } from "../../redux/actions/snackbarActions";
import { useDispatch } from "react-redux";


const MainWrapper = experimentalStyled("div")(({ theme }) => ({
	height: "100vh",
	display: "flex",
	width: "100vw",
}));
const PageWrapper = experimentalStyled("div")(({ theme }) => ({
	width: "100%",
	height: `100%`,
	// backgroundColor: Colours.white,

	[theme.breakpoints.up("lg")]: {
		paddingTop: TopbarHeight,
	},
	[theme.breakpoints.down("lg")]: {
		paddingTop: "64px",
	},
}));

const FullLayout = ({  token }) => {
	//
	const { error, isAuthenticated, user } = useAuth0();

	const [isSidebarOpen, setSidebarOpen] = useState(true);
	const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
	const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
const [tutorialPassed, setTutorialPassed] = useState(window.localStorage.getItem('tutorial2Passed')  === 'true' ? true : false)
	const {
		setState,
		state: { run, stepIndex, steps },
	} = useAppContext();
	const navigate = useNavigate();

	useMount(() => {
		setState({
			steps: [
				{
					target: ".home",
					content: (
						<>
							<Typography variant="h2">Welcome to Max Cloud </Typography>
							<Typography>Let's show you around!</Typography>
						</>
					),
					disableBeacon: true,
					placement: "center",
				},
				{
					target: ".searchBar",
					content: (
						<>
							<Typography bold size="large">
								Quick Search{" "}
							</Typography>
							<Typography>
								Use the search bar to quickly filter the grid using key terms
							</Typography>
						</>
					),
				},
				// {
				// 	target: ".importButton",
				// 	content: (
				// 		<>
				// 			<Typography bold size="large">
				// 				Import
				// 			</Typography>
				// 			<Typography>
				// 				Use the import feature to bulk import items
				// 			</Typography>
				// 		</>
				// 	),
				// },
				{
					target: ".exportButton",
					content: (
						<>
							<Typography bold size="large">
								Export
							</Typography>
							<Typography>
								Use the export feature to export the data to a CSV file
							</Typography>
						</>
					),
				},
				{
					target: ".new",
					content: (
						<>
							<Typography bold size="large">
								New Item
							</Typography>
							<Typography>Click here to create a new item</Typography>
						</>
					),
					placement: "left",
				},
				{
					target: ".actions",
					content: (
						<>
							<Typography bold size="large">
								Actions
							</Typography>
							<Typography>
								Use the actions to edit, delete or navigate to see more details
							</Typography>
						</>
					),
					// placement: "left",
				},
				{
					target: ".listSelect",
					content: (
						<>
							<Typography>
								Change the list being edited, or create a new one
							</Typography>
						</>
					),
					// placement: "left",
				},
				{
					target: ".variantSelect",
					content: (
						<>
							<Typography>
								Select the variant of the current list to be edited or create a
								new variant{" "}
							</Typography>
						</>
					),
					// placement: "left",
				},
				{
					target: ".layoutSelect",
					content: (
						<>
							<Typography>Change the layout of the current list </Typography>
						</>
					),
					// placement: "left",
				},
				{
					target: ".advanced",
					content: (
						<Typography>Edit the advanced settings of the list.</Typography>
					),
					// placement: "left",
				},
				{
					target: ".deleteVariant",
					content: (
						<Typography>Delete the currently selected list variant.</Typography>
					),
					// placement: "left",
				},
				{
					target: ".pages",
					content: (
						<Typography>
							Change the current page, add a new page, delete a page or change
							the page's order
						</Typography>
					),
					// placement: "left",
				},
				{
					target: ".copyPaste",
					content: (
						<Typography>
							Change the background and foreground colours by toggling the
							format button on and selecting the buttons you wish to change
						</Typography>
					),
					// placement: "left",
				},
				{
					target: ".undo",
					content: (
						<Typography>
							Revert all screen changes back to its initial state
						</Typography>
					),
					// placement: "left",
				},
				{
					target: ".saveList",
					content: (
						<Typography>
							Save the list changes and send them to the till
						</Typography>
					),
					// placement: "left",
				},

				{
					target: ".button",
					content: (
						<Typography>
							Create and edit screens by dragging and dropping buttons. Single
							click a button to open up the edit panel. Double click a button
							linked to a different screen to navigate.
						</Typography>
					),
					placement: "left",
				},
			],
		});
	});


	const handleCallback = (data) => {
		const { action, index, lifecycle, type,status } = data;
		// console.log("index, type, action", index, type, action);
		const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
		// console.log("next step index", nextStepIndex);
			if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status) || action === 'stop') {
			  window.localStorage.setItem('tutorial2Passed', 'true');
			  setTutorialPassed(true);
			}
	
		if (action === "close") {
			setState({ run: false, stepIndex: 0, tourActive: false });
		} else if (type === "step:after") {
			if (action === "next") {
				if (index === 4) {
					setState({ run: true, stepIndex: 5 });

					navigate("/designer");
				} else if (index === 0) {
					navigate("/items");
					setState({ run: true, stepIndex: 1 });
				} else {
					setState({
						run: true,
						stepIndex: nextStepIndex,
						tourActive: true,
					});
				}
			}

			if (action === "prev") {
				if (index === 1) {
					navigate("/home");
					setState({ run: true, stepIndex: 0 });
				} else if (index === 6) {
					navigate("/items");
					setState({
						run: true,
						stepIndex: nextStepIndex,
						tourActive: true,
					});
				} else {
					setState({
						run: true,
						stepIndex: nextStepIndex,
						tourActive: true,
					});
				}
			}
		} else if (action === "reset" || lifecycle === "complete") {
			setState({ run: false, stepIndex: 0, tourActive: false });
		}
	};

	const [snackbar, setSnackbar] = React.useState({
		open: false,
		message: [],
		duration: 3000,
		severity: "success",
	})
	const {getAccessTokenSilently} = useAuth0()
const [baking,setIsBaking] = React.useState([])
const [isSyncing,setIsSyncing] = React.useState(false)
const dispatch = useDispatch()
const refetch = async () => {
	try {
		const token = await getAccessTokenSilently();
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		await axios.get(
			`${APP_URL}/api/pos/getBakeProgress?debug=true`,
			 config)
			.then((response) => {
				setIsBaking(response.data);
				if(response.data.length === 0) {
					dispatch(toggleSnackbarOpen({
						message: ["Sync complete"],
						duration: 4000,
						severity: "success",
						open:true

					}));

				}
			})
			.catch((error) => {
				console.log("fe err useFetch", error);
			});
	} catch (error) {
		console.log(error)
	} finally {
		setIsSyncing(false);
	}
}

React.useEffect(() => {
	if(baking.length > 0) {
	setTimeout(() => {
		refetch()
	}, 20000);
}

},[baking, setIsBaking])

console.log(tutorialPassed)

	if (error) {
		return <MyFallbackComponent error={error.message} />;
	} else
		return (
			<>
				{isAuthenticated && user ? (
					// <UserContextProvider>

					<DataGridContextProvider>
<RowsContextProvider >
						{/* <SnackbarContext.Provider value={{snackbar, setSnackbar}}> */}
						<BakingContext.Provider value={{baking, setIsBaking, isSyncing, setIsSyncing}}>

						<MainWrapper className="home">
							<Header
								sx={{
									display: {
										lg: "none",
										xs: "inline",
									},
									paddingLeft: isSidebarOpen && lgUp ? "200px" : "",
									backgroundColor: "white",
									height: lgUp ? TopbarHeight : "60px",
								}}
								toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
								toggleMobileSidebar={() => setMobileSidebarOpen(true)}
							/>

							<Sidebar
								isSidebarOpen={isSidebarOpen}
								isMobileSidebarOpen={isMobileSidebarOpen}
								onSidebarClose={() => setMobileSidebarOpen(false)}
								// loggedinuser={loggedInUser}
								token={token}
							/>
							{!tutorialPassed &&
							<Joyride
								callback={handleCallback}
								continuous
								run={run}
								stepIndex={stepIndex}
								steps={steps}
								styles={{
									options: {
										arrowColor: Colours.gsblue,
										backgroundColor: "white",
										primaryColor: Colours.gsblue,
										textColor: Colours.darkGrey,
									},
								}}
								locale={{
									back: "Back",
									close: "Close",
									last: "Finish",
									next: "Next",
									skip: "Skip",
								}}
							/>}
							<PageWrapper>
								<Container
									maxWidth={false}
									sx={{
										paddingLeft: isSidebarOpen && lgUp ? "200px!important" : "",
										flex: 1,
										// pt: 3,
										backgroundColor: "white",
									}}
								>
									<Outlet />
									{/* <Footer /> */}
								</Container>

							</PageWrapper>
							{/* <CustomSnackBar/> */}
							<SuccessSnackbar/>
<NoPermissionDialog/>
						</MainWrapper>
						</BakingContext.Provider>

						{/* </SnackbarContext.Provider> */}
						</RowsContextProvider>
					</DataGridContextProvider>
					// </UserContextProvider>

				) : (
					<LandingPage />
				)}
			</>
		);
};

export default FullLayout;
