import React from "react";
import {
	DragOverlay
} from "@dnd-kit/core";
import {
	rectSwappingStrategy,
	SortableContext
} from "@dnd-kit/sortable";
import { Box } from "@mui/material";
import { Colours } from "../../../assets/global/Theme-variable";
import { CursorContext } from "../../../components/CursorProvider";
import GridContext from "../../../context/GridContext";
import DragOverlayItem from "./DragOverlay";
import NewGridItem from "./NewGridItem";
import { checkPermissions } from "../../AccessControl";
import { useUserState } from "../../../context/UserContext";

export default function FunctionBar({
	setOpen,
	open,
	// items,
	onDrop,
	activeId,
	editBar,
	colNo,
	rowNo,
	list,
	handleOpen,
	setItem,
	openItem,
	cellCount,
	draggingItem,
	listArea,
	setDraggingItem,
	...props
}) {

	const { variant, page, copyStyles } = React.useContext(GridContext);
	const { onCursor } = React.useContext(CursorContext);
const userPermission = useUserState()
	return (
		<>
			{variant?.list_details?.location !== "F3" ? (
				<Box
					sx={{
						width: "100%",
						height: "100%",
						backgroundColor: Colours.gsgrey,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
			
				</Box>
			) : (
				<Box
					sx={{
						display: "grid",
						gridAutoFlow: "dense",
						gridTemplateColumns: `repeat(${colNo}, 1fr)`,
						gridTemplateRows: `repeat(${rowNo}, 1fr)`,
						cursor: copyStyles === true ? "none" : "pointer",
						width: "100%",
						minHeight: "100%",
						flex: 1,
						gridAutoRows: "0",
						overflowY: "hidden",
						opacity: listArea?.displayName !== "Bottom Bar" ? "1" : "1",
					}}
					onMouseEnter={() => (copyStyles === true ? onCursor("left") : null)}
					onMouseLeave={copyStyles === true ? onCursor : null}
					key={"fb"}
				>
					<SortableContext
					
						disabled={variant?.list_details?.location !== "F3" || checkPermissions(userPermission, {
							page: "screens",
							// allow: false,
							action: "edit",
						}) === false}
						items={variant.list_details.buttons}
						strategy={rectSwappingStrategy}
					>
						{variant?.list_details?.buttons
							?.filter((button) => button.pageNo === page)
							// .sort(sortItems)
							.map((item, index) => (
								<NewGridItem
									item={item}
									key={item.id}
									id={item.id}
									activeId={activeId}
									{...props}
									handleOpen={handleOpen}
									disableSelection={variant?.list_details?.location !== "F3"}
								/>
							))}
					</SortableContext>
					<DragOverlay>
						{activeId ? <DragOverlayItem id={activeId} /> : null}
					</DragOverlay>
				</Box>
			)}
		</>
	);
}

