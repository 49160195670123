import React, { createContext, useCallback, useContext, useState } from "react";
import { APP_URL } from "../API/apiConfig";
import axios from "axios";
import { getConfig } from "../views/profile/config";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { toggleSnackbarOpen } from "../redux/actions/snackbarActions";
const _ = require('lodash');

const UserStateContext = createContext()
const LoggedInUserContext = createContext()
const LoggedInUserUpdaterContext = createContext()
const UserUpdaterContext = createContext()


function UserContextProvider(props) {
	const { user } = useAuth0();
const [userPermission, setUserPermission] = useState(null)
    const [loggedInUser, setLoggedInUser] = useState(user ? user : null);

    return (
      <UserStateContext.Provider value={userPermission}>
        <UserUpdaterContext.Provider value={setUserPermission}>
        <LoggedInUserContext.Provider value={loggedInUser}>
<LoggedInUserUpdaterContext.Provider value={setLoggedInUser}>
          {props.children}
          </LoggedInUserUpdaterContext.Provider>
          </LoggedInUserContext.Provider >
        </UserUpdaterContext.Provider>
      </UserStateContext.Provider>
    )
  }

  function useUserState() {
    const userState = React.useContext(UserStateContext)
    if (typeof userState === 'undefined') {
        console.log('userState must be used within a UserProvider')
    }
    return userState
  }

  function useLoggedInUserState() {
    const loggedInUser = React.useContext(LoggedInUserContext)

    if (typeof loggedInUser === 'undefined') {
      console.log('loggedInUserState must be used within a UserProvider')
    }
    return loggedInUser
  }

  function useLoggedInUserUpdater(user) {
    const setLoggedInUser = React.useContext(LoggedInUserUpdaterContext)
    const updateLoggedInUser = ((user) => {
            setLoggedInUser(user)
        })   
    return updateLoggedInUser
  }


  function useUserUpdater() {
  const setUserPermission = React.useContext(UserUpdaterContext)
  const updateLoggedInUser = useLoggedInUserUpdater()
const userPermission = useUserState()
  const { apiOrigin = APP_URL,  } = getConfig();
  const { getAccessTokenSilently, user, } = useAuth0();
// const {snackBar, setSnackbar} = React.useContext(SnackbarContext)
  if (typeof setUserPermission === 'undefined') {
    throw new Error('useUserUpdated must be used within a userProvider')
  }

const getPermissions = useCallback((liu) => {
    if (liu) {
        let userPermissions = {
            name: "",
            user_ref: liu?.app_metadata?.user_ref,
            user_type: liu?.app_metadata?.user_type,
            permissions: [],
            userTypePermissions: [],
        };
        (liu?.app_metadata?.userType_permissions?.rules || []).forEach(
            (rule) => {
                userPermissions.permissions.push(rule);
            }
        );

        (liu?.app_metadata?.user_permissions?.rules || []).forEach(
            (rule) => {
                userPermissions.permissions.push(rule);
            }
        );
        userPermissions.permissions.forEach((rule) => {
            if (!(rule.page instanceof Array)) rule.page = [rule.page];
            if (!(rule.api instanceof Array)) rule.api = [rule.api];
            if (!rule.actions && rule.action) rule.actions = rule.action;
            if (!(rule.actions instanceof Array)) rule.actions = [rule.actions];

            if (rule.page.includes("plu") && !rule.page.includes("items"))
                rule.page.push("items");
            if (rule.page.includes("list") && !rule.page.includes("screens"))
                rule.page.push("screens");
        });
        return userPermissions

    }
},[])

const dispatch = useDispatch();

  const getUser = useCallback(async () => {

    try {
        const token = await getAccessTokenSilently();
  
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        const body = {
            userid: user?.sub,
        };

        await axios
            .post(`${apiOrigin}/api/pos/auth0user`, body, config)
            .then((response) => {
                const newPermissions = getPermissions(response.data)
                // console.log(response.data)
                // console.log('up:',userPermission, 'np:', newPermissions)
                if(!_.isEqual(userPermission,newPermissions)) {
                    // console.log('not equal - updating user')
                    updateLoggedInUser(response?.data)
                    if(userPermission) {
                    dispatch(toggleSnackbarOpen({open: true, message: "Your user permissions have changed",severity:'info', duration:3000}));
                    }
                    setUserPermission(newPermissions)

        
                } 
                // else {
                //     console.log('equal - no changes to make')
                //     // dispatch(toggleSnackbarOpen("Your user permissions have changed", 'info', 3000));
                // }
    }
            )
            .catch((error) => {
                console.log(error)
                            });
            
    } catch (error) {
        // setError(error);
    } finally {
        // setIsLoading(false);
    }
}, [ apiOrigin,getPermissions, user?.sub, setUserPermission, userPermission,getAccessTokenSilently,updateLoggedInUser]);

  return getUser
}

export {UserContextProvider, useUserState, useUserUpdater, useLoggedInUserState,useLoggedInUserUpdater}
