import {
	Box,
	Button,
	Checkbox,
	InputLabel,
	Modal,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import React, { useContext } from "react";
import CustomTextField from "./CustomTextField";
import { Colours } from "../assets/global/Theme-variable";
import SelectList from "./SelectList";
import axios from "axios";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import { DataGridContext } from "../context/DataGridContext";
import { API_ENDPOINTS } from "../API/apiConfig";
import { useLoggedInUserState } from "../context/UserContext";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	borderRadius: "20px",
	width: 400,
	minHeight: 400,
	bgcolor: "white",
	boxShadow: 24,
	p: 4,
	display: "flex",
	flexDirection: "column",
};

export default function FeedbackModal({
	modalVisible,
	setModalVisible,
	// onSubmit,
}) {
	const { getAccessTokenSilently } = useAuth0();
	// const { loggedInUser } = useContext(DataGridContext);
	const loggedInUser= useLoggedInUserState();

	const {
		control,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm({
		defaultValues: {
			title: "I have a suggestion",
			description: "",
			author: loggedInUser?.nickname,
		},
	});

	const reason = useWatch({
		control,
		name: "title",
	});

	const onSubmit = (data) => console.log(data);

	const options = [
		"I have a suggestion",
		// "I have an issue",
		"I have some general feedback",
	];
	// const [reason, setReason] = React.useState(options[0]);

	const postFeedback = async () => {
		const token = await getAccessTokenSilently();

		const config = {
			headers: { Authorization: `Bearer ${token}` },
		};

		// const feedback = {
		// 	macro_name: "Testing New Method",
		// 	macro_details: {},
		// };
		const form = getValues();
		const body = {
			feedback: {
				// author: form.author,
				author: "Emma Maddocks",
				description: form.description,
				title: form.title,
			},
			// author: form.author,
			// description: form.description,
			// title: form.title,
		};

		console.log(body);
		axios
			.post(
				API_ENDPOINTS.sendFeedbackEmail, // Using the endpoint from API_ENDPOINTS
				body,
				config
			)
			.then(function (response) {
				console.log("res", response);
				setModalVisible(false);
			})
			.catch(function (error) {
				console.log("res", error);
			});
	};

	return (
		<Modal open={modalVisible}>
			<Box sx={style}>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						alignItems: "center",
						mt: 2,
					}}
				>
					<Typography variant="h3" sx={{ colour: Colours.gsblue }}>
						Feedback
					</Typography>
					<Button
						variant="contained"
						size="medium"
						sx={{ alignSelf: "flex-end" }}
						onClick={() => setModalVisible(!modalVisible)}
					>
						X
					</Button>
				</Box>
				<Typography sx={{ fontSize: 14, mt: 2, textAlign: "left" }}>
					What kind of feedback do you have?
				</Typography>
				<Controller
					control={control}
					rules={{
						required: true,
					}}
					render={({ field: { onChange, onBlur, value } }) => (
						<SelectList
							options={options}
							// setOption={setReason}
							option={value}
							marginBottom={2}
							// onChange={(e) => setReason(e.target.value)}
							onChange={onChange}
						/>
					)}
					name="title"
				/>
				<Controller
					control={control}
					rules={{
						required: true,
					}}
					render={({ field: { onChange, onBlur, value } }) => (
						<CustomTextField
							placeholder="Start typing..."
							type="standard"
							multiline={true}
							value={value}
							// disabled={checkPermissions(userPermission, {
							// 	page: "items",
							// 	allow: false,
							// 	action: "edit",
							// })}
							onBlur={onBlur}
							onChange={onChange}
							label={
								<Typography
									sx={{
										fontSize: 18,
										mb: 0,
										color: Colours.gsblue,
										justifySelf: "center",
									}}
								>
									{reason === "I have a suggestion"
										? "Please let us know your suggestion"
										: reason === "I have an issue"
										? "Please describe your issue"
										: reason === "I have some general feedback"
										? "We'd love to hear your thoughts!"
										: null}
								</Typography>
							}
						/>
					)}
					name="description"
				/>
				{errors.description && <Typography>This is required.</Typography>}
				<Typography sx={{ fontSize: 12, mt: 2, textAlign: "center" }}>
					We greatly appreciate your feedback, and may use it to improve our
					solution.
				</Typography>
				<Typography sx={{ fontSize: 12, mt: 2, textAlign: "center" }}>
					Unfortunately we cannot reply to feedback, if you need to report an
					urgent issue requiring a response please contact us on:
					<Typography
						sx={{ fontSize: 12, color: Colours.gsblue, textAlign: "center" }}
					>
						support@gs-systems.co.uk
					</Typography>
					or
					<Typography
						sx={{ fontSize: 12, color: Colours.gsblue, textAlign: "center" }}
					>
						0161 483 5595
					</Typography>
				</Typography>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						justifySelf: "flex-end",
						mt: 2,
					}}
				>
					<Button
						variant="outlined"
						size="medium"
						sx={{ alignSelf: "flex-end" }}
						onClick={() => setModalVisible(false)}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						size="medium"
						sx={{ alignSelf: "flex-end" }}
						onClick={handleSubmit(postFeedback)}
						// onClick={postFeedback}
					>
						Send
					</Button>
				</Box>
			</Box>
		</Modal>
	);
}
