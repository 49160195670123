import {
	Button,
	DialogContent,
	List,
	ListItem,
	Stack,
	Typography,
} from "@mui/material";
import { Dialog, Fade, } from "@mui/material";
import React, { forwardRef } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { Colours } from "../../../assets/global/Theme-variable";

const Transition = forwardRef(function Transition(props, ref) {
	return <Fade ref={ref} {...props} />;
});

function ConfirmBox({
	open,
	closeDialog,
	deleteFunction,
}) {

	return (
		<Dialog
			open={open?.status}
			maxWidth="md"
			scroll="body"
			onClose={closeDialog}
			TransitionComponent={Transition}
		>
			<DialogContent sx={{ px: 4, py: 4, position: "relative" }}>
				<Stack spacing={2}>
					<Stack
						direction="row"
						// spacing={2}
						alignItems="center"
						justifyContent="space-between"
						mb={2}
					>
						<Typography sx={{ mb: 1 }} variant="h2">
							Confirm Delete
						</Typography>
					</Stack>
					<Stack spacing={2} mb={2}>
						<Typography sx={{ mb: 2 }}>
							Are you sure you want to delete the selected row?
						</Typography>
						{open?.msg && (
							<>
								<Typography variant="body1">{open?.msg}</Typography>
								<List sx={{ mt: 0, ml: 2 }} dense={true}>
									{open?.lists?.map((list) => (
										<ListItem component="div" disablePadding sx={{ ml: 4 }}>
											<CircleIcon
												sx={{
													fontSize: 10,
													color: Colours.gsblue,
													textAlign: "center",
													mr: 1,
												}}
											/>
											<Typography>{list?.list_name ? list?.list_name : list?.user_name ? list?.user_name :null}</Typography>
										</ListItem>
									))}
								</List>
							</>
						)}
					</Stack>
					<Stack
						direction="row"
						spacing={2}
						justifyContent="flex-end"
						alignItems="center"
					>
						<Button
							onClick={closeDialog}
							size="medium"
							variant="outlined"
						>
							Cancel
						</Button>
						<Button
							onClick={deleteFunction}
							size="medium"
							variant="contained"
						>
							Delete
						</Button>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	);
}

export default ConfirmBox;
