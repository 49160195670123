import * as React from "react";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import {
	Box,
	Chip,
	InputLabel,
	ListItemText,
	TextField,
	Typography,
} from "@mui/material";
import { DataGridContext } from "../../context/DataGridContext";
import CloseIcon from "@mui/icons-material/Close";
import { Colours } from "../../assets/global/Theme-variable";
import { getTextColour } from "./utils";
import transitions from "@material-ui/core/styles/transitions";

export default function AutoCompleteTagCell(props) {
	const { data, setData } = React.useContext(DataGridContext);

	const filteredTags = data["tags"].filter(
		(obj) => props?.tags?.includes(obj?.tag_ref) && obj.tag_isDeleted === 0
	);

	return (
		<Box
			sx={{
				width: "100%",
				display: "flex",
				flex: 1,
				flexDirection: "column",
				alignItems: "stretch",
				// maxHeight: props?.height && props?.height,
			}}
		>
			<InputLabel shrink htmlFor={"Tags"}>
				<Typography
					sx={{
						fontSize: 18,
						mb: 0,
						color: Colours.gsblue,
						justifySelf: "flex-start",
					}}
				>
					Tags
				</Typography>
			</InputLabel>
			<Autocomplete
				multiple
				id="tags"
				value={filteredTags}
				onChange={props?.onChange} // prints the selected value
				options={data["tags"].filter((i) => i.tag_isDeleted === 0)}
				disabled={props?.disabled}
				getOptionLabel={(option) => option?.tag_name}
				renderOption={(props, item) => (
					<li {...props} key={item?.key}>
						<ListItemText>{item?.tag_name}</ListItemText>
					</li>
				)}
				filterSelectedOptions
				sx={{
					flex: 1,

					".MuiAutocomplete-inputRoot": {
						// flexWrap: "nowrap !important",
						// backgroundColor:  Colours.fieldBackground,
						overflow: "auto",
						zIndex: 10,
						border: "1px solid #E0E3E7",
						borderColor: "#E0E3E7",
						flex: 1,
						transition: transitions.create([
							"border-color",
							"background-color",
							"box-shadow",
						]),
						"&:focus-within": {
							boxShadow: `${alpha(Colours.gsblue, 0.25)} 0 0 0 0.2rem`,
						},
					},
					".css-1wngqrs-MuiInputBase-root-MuiOutlinedInput-root": {
						flex: 1,
					},
					".MuiOutlinedInput-notchedOutline": {
						border: "none",
						// border: "1px solid #2D3843",
						// borderColor: "#2D3843",
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						border: "1px solid",

						// boxShadow: `${alpha(Colours.gsblue, 0.25)} 0 0 0 0.2rem`,
						borderColor: "#2a9ec5",
					},

					// "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
					// 	border: "none",
					// },
					// "&:hover .MuiOutlinedInput-notchedOutline": {
					// 	border: "none",
					// },
					// ".MuiSvgIcon-root ": {
					// 	fill: "white !important",
					// },
				}}
				variant={"standard"}
				defaultValue={filteredTags}
				limitTags={props?.limit ? props.limit : 3}
				renderTags={(value, getTagProps) =>
					value.map((option, index) => {
						const { key, ...rest } = getTagProps({ index });

						return (
						<Chip
						key={key}
						{...rest}
							label={
								<Typography
									style={{
										whiteSpace: "normal",
										color: getTextColour(option?.tag_details?.styling?.colour)
											.colour,
										fontSize: 12,
									}}
								>
									{option?.tag_name}
								</Typography>
							}
							deleteIcon={
								<CloseIcon
									color={
										getTextColour(option?.tag_details?.styling?.colour)?.colour
									}
								/>
							}
							sx={{
								// height: "100%",
								backgroundColor: option?.tag_details?.styling?.colour,
								padding: "2px 4px 2px 8px",
								borderRadius: 2,

								"& svg": {
									fontSize: "12px",
									cursor: "pointer",
									padding: "4px",
									color: `${
										getTextColour(option?.tag_details?.styling.colour).colour
									} !important`,
								},
							}}
						/>
					)})
				}
				renderInput={(params) => (
					<TextField
						{...params}
						// label="Tags"
						variant="outlined"
						// placeholder="Tag"
						sx={{ flex: 1 }}
					/>
				)}
			/>
		</Box>
	);
}
