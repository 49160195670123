import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { LoadingButton } from "@mui/lab";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	IconButton
} from "@mui/material";
import React from "react";
import { Colours } from "../../../assets/global/Theme-variable";

export default function ErrorDialog({
	errorMessage,
	// onClose,
	setErrorMessage,
	isSaving,

}) {
	const handleClose = () => {
		setErrorMessage((prev) => ({ ...prev, open: false }));
		// onClose(); // You can call onClose here if needed
	};

	return (
		<Dialog
			maxWidth={"xs"}
			open={errorMessage.open || false}
			onClose={handleClose}
			keepMounted
			sx={{ padding: 2 }}
		>
			<DialogTitle
				sx={{
					color: Colours.gsblue,
					fontSize: 18,
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				{errorMessage.button2text}
				<IconButton
					aria-label="close"
					sx={{ alignSelf: "flex-end" }}
					onClick={handleClose}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>{errorMessage.text}</DialogContentText>
				<FormControl sx={{ width: '100%' }}>
			{/* <InputLabel id="demo-simple-select-label">Variants</InputLabel>
			<Select
				// width={width}
				// sx={{ width: width }}
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				defaultValue={variantOptions.filter((v)=> v?.list_variant !== variant?.list_variant)[0]}
				size="small"
				label="Main list"
				onChange={(e, newValue) =>setNewMain(e.target.value)}
				// renderValue={(value) => value.list_name}
			>
				{variantOptions?.filter((v)=> v?.list_variant !== variant?.list_variant).map((option, index) => (
					<MenuItem key={index} value={option}>
						{option.list_name}
					</MenuItem>
				))}
				
			</Select> */}
		</FormControl>
			</DialogContent>
			{(errorMessage.button1func || errorMessage.button2func) && 
			<DialogActions
				sx={{ justifyContent: "space-between", pl: 3, pr: 3, pb: 2 }}
			>
				<Button
					// text={errorMessage.button1text}
					variant="outlined"
					onClick={
						errorMessage.button1func ? errorMessage.button1func : handleClose
					}
				>
					{errorMessage.button1text}
				</Button>
				<LoadingButton
					size="small"
					onClick={errorMessage.button2func}
					loading={isSaving}
					variant={!isSaving ? "gradient" : "outlined"}
					loadingPosition="start"
					startIcon={<SaveIcon />}
					disabled={isSaving}
				>
					<span>{errorMessage.button2text}</span>
				</LoadingButton>
			</DialogActions>
			}
		</Dialog>
	);
}
