import {
	Alert,
	Autocomplete,
	Box,
	Button,
	Collapse,
	IconButton,
	MenuItem,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import { Controller, useForm, useFormContext } from "react-hook-form";
import axios from "axios";
import { nanoid } from "nanoid";
import { DataGridContext } from "../context/DataGridContext";
import { Colours } from "../assets/global/Theme-variable";
import { useAuth0 } from "@auth0/auth0-react";
import { API_ENDPOINTS } from "../API/apiConfig";
import ReactHookFormSelect from "./ReactHookFormSelect";
import ReactHookFormMultiSelectChip from "./ReactHookFormMultiSelectChip";
import PermissionsModal from "./PermissionsModal";
import CloseIcon from "@mui/icons-material/Close";

export default function NewRoleModal({
	modalVisible,
	setModalVisible,
	type,
	url,
	context,
	rowID,
	setRowID,
	setType,
	methods,
	modalConfig,
}) {
	// const {
	// 	handleSubmit,
	// 	reset,
	// 	control,
	// 	register,
	// 	getValues,
	// 	formState: { errors },
	// } = useForm({});
	const { control, formState, watch } = useFormContext();
	// console.log(modalConfig);
	const { data,} =
		React.useContext(DataGridContext);
	const [userTypePermissionObj, setUserTypePermissionObj] = React.useState([
		{
			page: ["*"],
			allow: true,
			actions: ["view"],
		},
		{
			page: ["*"],
			allow: true,
			actions: ["edit"],
		},
		{
			page: ["*"],
			allow: true,
			actions: ["create"],
		},
		{
			page: ["*"],
			allow: true,
			actions: ["delete"],
		},
	]);
	// const blankPermissions = [
	// 	{
	// 		page: ["*"],
	// 		actions: ["*"],
	// 		allow: true,
	// 		id: nanoid(),
	// 	},
	// ];

	const [error, setError] = React.useState(null);
	// const pages = ["Staff", "Printers", "Categories", "Major Categories"];
	const obj = watch("policy_details.rules");
	console.log(obj);
	const [permissionObject, setPermissionObj] = React.useState(
		// modalConfig.editRow.row !== {}
		// 	? modalConfig.editRow.row.policy_details.rules
		// 	:
		obj?.map((modifier) => ({
			id: nanoid(),
			...modifier,
		}))
	);
	// console.log(modalConfig.editRow);
	React.useEffect(() => {
		// if (modalConfig?.editRow !== {}) {
			if (
				modalConfig?.editRow?.row?.policy_details?.rules?.findIndex(
					(i) =>
						(i?.page?.includes("*") || i?.api?.includes("*")) &&
						i?.actions?.includes("*")
				) === 0
			) {
				// const newRules = rowID.policy_details.rules.filter(
				// 	(i, index) =>
				// 		(i?.page?.includes("*") || i?.api?.includes("*")) &&
				// 		i?.actions?.includes("*")
				// );
				// const newRules = [];
				// console.log(newRules);
				// newRules.push(...userTypePermissionObj);
				// modalConfig.editRow.row.policy_details.rules = [
				// 	...userTypePermissionObj,
				// ];
				// console.log(newRules);
				// } else {
				// }
				// 		(i.actions?.includes(action) || i.actions?.includes("*")))
				const modifiersOrig =
					modalConfig?.editRow?.row?.policy_details.rules?.map((modifier) => ({
						id: nanoid(),
						...modifier,
					}));
				setPermissionObj(modifiersOrig);
			}
			// setPermissionObj(rowID.policy_details.rules);
			// }
		// }
	}, [modalConfig?.editRow]);


	const permissionList = [
		{
			page: "screens",
			dbName: "list",
			actions: ["edit", "create", "delete", "view", "toggleListSending"],
		},
		{
			page: "items",
			dbName: "plu",
			actions: ["edit", "create", "delete", "view"],
		},
		{
			page: "tags",
			dbName: "tag",
			actions: ["edit", "create", "delete", "view"],
		},
		{
			page: "staff",
			dbName: "staff",
			actions: ["edit", "create", "delete", "view"],
		},
		{
			page: "major categories",
			dbName: "majorGroups",
			actions: ["edit", "create", "delete", "view"],
		},
		{
			page: "categories",
			dbName: "salesGroups",
			actions: ["edit", "create", "delete", "view"],
		},
		{
			page: "configuration",
			dbName: "configuration",
			actions: ["edit", "create", "delete", "view"],
		},
		{
			page: "pricing",
			dbName: "pricing",
			actions: ["edit", "create", "delete", "view"],
		},
		{
			page: "printers",
			dbName: "printers",
			actions: ["edit", "create", "delete", "view"],
		},
		{
			page: "sites",
			dbName: "sites",
			actions: ["edit", "create", "delete", "view"],
		},
		{
			page: "permissions",
			dbName: "permissions",
			actions: ["edit", "create", "delete", "view"],
		},
	];

	return (
		<Box
			sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2 }}
		>
			{/* <Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							alignItems: "center",
							mb: 2,
						}}
					>
						<Typography
							variant="h5"
							sx={{ mb: 0, color: Colours.gsblue, justifySelf: "center" }}
						>
							{rowID ? "Edit" : "New"}
							{type === "users"
								? " User"
								: type === "userTypes"
								? "  User Type"
								: " Policy"}
						</Typography>
						<Button
							variant="contained"
							size="medium"
							sx={{ alignSelf: "flex-end" }}
							onClick={() => handleClose()}
						>
							X
						</Button>
					</Box> */}
			{type === "users" ? (
				<>
					<Box>
					
						<TextField
							label={"User Name"}
							size="small"
							type="text"
							sx={{ width: "100%" }}
							InputLabelProps={{
								shrink: true,
							}}
							{...methods.register(`user_name`, {
								required: {
									value: true,
									message: "User name is required",
								},

								validate: (value) =>
									modalConfig?.isNew === true &&
									(!data?.users?.some((user) => user?.user_name === value && user.user_isDeleted === 0) ||
										"A user with this name already exists"),
							})}
						/>
						{formState?.errors?.user_name?.message && (
							<Typography sx={{ color: Colours.red, fontSize: 12 }}>
								{formState?.errors?.user_name?.message}
							</Typography>
						)}
					</Box>
					<Box>
						<TextField
							label={"User email"}
							size="small"
							type="text"
							sx={{ width: "100%", mb: 2, }}
							InputLabelProps={{
								shrink: true,
																  
							}}
							{...methods.register(`user_details.login`, {
								required: {
									value: true,
									message: "Email address is required",
								},
								pattern: {
									value: /^\S+@\S+$/i,
									message: "Email does not match the required format",
								},

								validate: (value) =>
									!data?.users?.some(
										(user) => user.user_details.login === value && user.user_isDeleted === 0
									) || "A user with this login already exists",
							})}
						/>
						{formState?.errors?.user_details?.login && (
							<Typography sx={{ color: Colours.red, fontSize: 12 }}>
								{formState?.errors?.user_details?.login?.message}
							</Typography>
						)}
					</Box>
					<Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
						<ReactHookFormSelect
							id="userType_role"
							name="user_details.userType"
							label="User Type"
							control={control}
							errors={methods.errors}
							rules={{
								required: "User type is required",
							}}
						>
							{data?.userTypes?.filter((i)=> i.userType_isDeleted === 0).map((role) => (
								<MenuItem
									key={role?.userType_number}
									value={role?.userType_ref}
								>
									{role?.userType_name}
								</MenuItem>
							))}
						</ReactHookFormSelect>
						{formState?.errors?.user_details?.userType && (
							<Typography sx={{ color: Colours.red, fontSize: 12 }}>
								{formState?.errors?.user_details?.userType?.message}
							</Typography>
						)}
					</Box>
					<ReactHookFormMultiSelectChip
						id="userType_role"
						name="user_details[policies]"
						label="Additional Policies"
						control={control}
						defaultValue={""}
						selectoptions={data?.policies.filter(
							(i) => i.policy_isDeleted === 0
						)}
						fieldValueToSave="policy_ref"
					/>

					{/* <Controller
								name="user_details[tillAccess]"
								control={control}
								render={({ field }) => (
									<FormControlLabel
										control={<Checkbox />}
										label={
											<Typography sx={{ fontSize: 14 }}>Till Access</Typography>
										}
										{...field}
									/>
								)}
							/> */}
				</>
			) : type === "userTypes" ? (
				<>
					<Box>
						<TextField
							label={"User Type Name"}
							size="small"
							type="text"
							sx={{ width: "100%" }}
							InputLabelProps={{
								shrink: true,
							}}
							{...methods.register(`userType_name`, {
								required: {
									value: true,
									message: "Name is required",
								},

								validate: (value) => {
									if (modalConfig.isNew === true) {
										return (
											!data?.userTypes?.some(
												(user) => user.userType_name === value && user.user_isDeleted === 0
											) || "A user type with this name already exists"
										);
									}
								},
							})}
						/>
						{formState?.errors?.userType_name?.message && (
							<Typography sx={{ color: Colours.red, fontSize: 12 }}>
								{formState?.errors?.userType_name?.message}
							</Typography>
						)}
					</Box>
					<ReactHookFormMultiSelectChip
						id="userType_policies"
						name="userType_details.policies"
						label="Policies"
						control={control}
						defaultValue={""}
						selectoptions={data?.policies.filter(
							(i) => i.policy_isDeleted === 0
						)}
						fieldValueToSave="policy_ref"
					/>
					{/* <Controller
						render={({
							field: { onChange, value, ref, onBlur, name },
							...props
						}) => (
							<Autocomplete
								multiple
								filterSelectedOptions
								options={data?.policies}
								getOptionLabel={(option) => option.policy_name}
								ref={ref}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										InputLabelProps={{
											shrink: true,
										}}
										label={"Policies"}
									/>
								)}
								// name={name}
								// value={value ?? []}
								onChange={(e, data) => data.map((v) => v.policy_ref)}
								// onChange={(e, data) => onChange(data.map((v) => v[fieldValueToSave]))}
								// onChange={onChange}
								{...props}
								limitTags={5}
								size="small"
							/>
						)}
						name={"userType_details.policies"}
						control={control}
						// defaultValue={defaultValue}
						// onChange={(e, data) => data.map((v) => v.policy_ref)}
					/> */}
				</>
			) : (
				<>
					<Box>
						<TextField
							label={"Policy Name"}
							size="small"
							type="text"
							sx={{ width: "100%" }}
							InputLabelProps={{
								shrink: true,
							}}
							{...methods.register(`policy_name`, {
								required: {
									value: true,
									message: "Name is required",
								},

								validate: (value) => {
									if (modalConfig.isNew === true) {
										return (
											!data?.policies?.some(
												(policy) => policy.policy_name === value && policy.policy_isDeleted === 0
											) || "A policy with this name already exists"
										);
									}
								},
							})}
							// 	validate: (value) =>
							// 		!data?.policies?.some(
							// 			(policy) => policy.policy_name === value
							// 		) || "A policy with this name already exists",
							// })}

							defaultValue={rowID?.policy_name && rowID.policy_name}
						/>
						{formState?.errors?.policy_name && (
							<Typography sx={{ color: Colours.gsblue, fontSize: 12 }}>
								{formState?.errors?.policy_name?.message}
							</Typography>
						)}
					</Box>
					<PermissionsModal
						rows={permissionObject}
						setUserTypePermissionObj={setUserTypePermissionObj}
						userTypePermissionObj={userTypePermissionObj}
						permissionObject={permissionObject}
						setPermissionObj={setPermissionObj}
						permissionList={permissionList}
						rowID={rowID}
					/>
				</>
			)}
			<Collapse in={error}>
				<Alert
					severity="error"
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setError(null);
							}}
						>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}
					// sx={{ mb: 2 }}
				>
					{error}
				</Alert>
			</Collapse>
			{/* <Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
						}}
					>
						<Button
							variant="outlined"
							size="medium"
							sx={{ alignSelf: "flex-end" }}
							onClick={() => reset()}
						>
							Reset form
						</Button>

						<Button
							variant="contained"
							size="medium"
							sx={{ alignSelf: "flex-end" }}
							// type="submit"
							onClick={onSubmit}
						>
							Submit
						</Button>
					</Box> */}
		</Box>
		// 	</form>
		// </Modal>
	);
}
